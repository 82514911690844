import "./App.css";
import Routers from "./Routes";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme/theme";
import { useEffect, useState } from "react";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { WagmiConfig } from "wagmi";
import { mainnet } from "viem/chains";
import { projectLink } from "./constants";

const projectId = process.env.REACT_APP_WALLETCONNECT_PROJECT_ID;

if (!projectId) {
  throw new Error(
    "REACT_APP_WALLETCONNECT_PROJECT_ID is not set in the environment variables"
  );
}

// Create wagmiConfig
const metadata = {
  name: "The Fibs by Charlesai",
  description: "A Generative art drop by Charlesai",
  url: projectLink,
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const chains = [mainnet];
const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });

createWeb3Modal({ wagmiConfig, projectId, chains });

function App() {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    setReady(true);
  }, []);

  return (
    <>
      {ready ? (
        <ThemeProvider theme={theme}>
          <WagmiConfig config={wagmiConfig}>
            <Routers />
          </WagmiConfig>
        </ThemeProvider>
      ) : null}
    </>
  );
}

export default App;
