import { useEffect, useState } from "react";
import { useAccount, useNetwork } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { makeStyles } from "@mui/styles";
import { trimAddress } from "../utils/pureFunctions";
import { validChainId } from "../constants";
import { useSwitchNetwork } from "wagmi";
import SwitchNetworkPopup from "./SwitchNetworkPopup";

function Navbar() {
  const classes = useStyles();
  const { isConnected, address: account } = useAccount(); // Get the connected account address
  const { open } = useWeb3Modal();
  const { chain } = useNetwork();
  const { switchNetworkAsync } = useSwitchNetwork();
  const [switchNetworkPopup, setSwitchNetworkPopup] = useState(false);
  const [isSwitchingNetwork, setIsSwitchingNetwork] = useState(false);

  useEffect(() => {
    if (!chain) return;
    if (chain.id !== validChainId) {
      setSwitchNetworkPopup(true);
    }
  }, [chain]);

  return (
    <div className={classes.root}>
      <div className="buttons">
        <div onClick={() => open()} className={classes.connectWalletButton}>
          {isConnected && account ? trimAddress(account) : "connect wallet"}
        </div>
      </div>
      {switchNetworkAsync ? (
        <SwitchNetworkPopup
          open={switchNetworkPopup}
          action={async () => {
            setIsSwitchingNetwork(true);
            try {
              await switchNetworkAsync(validChainId);
            } catch {}
            setIsSwitchingNetwork(false);
            setSwitchNetworkPopup(false);
          }}
          pending={isSwitchingNetwork}
        />
      ) : null}
    </div>
  );
}

export default Navbar;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: 100,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0 20px",
    color: "white",
  },
  connectWalletButton: {
    backgroundColor: "#181818",
    boxShadow: "inset 3px 3px 12px #4646466A",
    borderRadius: "9px",
    textTransform: "none",
    padding: "8px 16px",
    cursor: "pointer",
    transition: "all 0.3s ease",
    color: "white",
    "&:hover": {
      boxShadow: "inset 3px 3px 12px #000",
    },
  },
}));
