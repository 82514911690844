import CryptoJS from "crypto-js";
import moment from "moment";
import { MerkleTree } from "merkletreejs";
import keccak256 from "keccak256";
import { timeUnits, iv, authKey } from "../constants";

const generateMerkleTree = (addresses: string[]) => {
  // Hash addresses
  const leaves = addresses.map((addr) => keccak256(addr));
  // Create Merkle Tree
  const tree = new MerkleTree(leaves, keccak256, { sortPairs: true });
  return tree;
};

const generateMerkleRoot = (addresses: string[]) => {
  // Create Merkle Tree
  const tree = generateMerkleTree(addresses);
  // Get the Merkle Root
  const root = tree.getRoot();

  return root;
};

export const generateHexMerkleRoot = (addresses: string[]) => {
  // Hash addresses
  const root = generateMerkleRoot(addresses);
  // Convert to hex
  const hexRoot: `0x${string}` = `0x${root.toString("hex")}`;
  return hexRoot;
};

export const generateMerkleProof = (
  addresses: string[],
  address: string
): string[] => {
  // Create Merkle Tree
  const tree = generateMerkleTree(addresses);
  // Get the Merkle Proof
  const proof = tree.getHexProof(keccak256(address));
  return proof;
};

export const trimAddress = (address: string) => {
  const length = address.length;
  return `${address.slice(0, 4)}...${address.slice(length - 6, length)}`;
};

/**
 * transform a duration to seconds
 * @param duration
 * @param unit
 * @returns
 */
export const formatDuration = (
  duration: number,
  unit: (typeof timeUnits)[number]
) => {
  switch (unit) {
    case "minutes":
      return duration * 60;
    case "hours":
      return duration * 60 * 60;
    case "days":
      return duration * 24 * 60 * 60;
    case "weeks":
      return duration * 7 * 24 * 60 * 60;
    default:
      return duration;
  }
};

export const getEncryptedAuthToken = (sig: string | null) => {
  if (!sig) return "";
  const timestamp = moment().unix() * 1000;
  var encrypted = CryptoJS.AES.encrypt(`${sig}-${timestamp}`, authKey, {
    mode: CryptoJS.mode.CBC,
    iv: iv,
  });

  return encrypted.toString();
};

export function createArtLink(delimiter = "-", ...args: string[]) {
  if (!args.length) return "";
  return args
    .join(" ")
    .split(" ")
    .filter((val) => val.trim())
    .join(delimiter)
    .replaceAll(/[#?/%]+/g, "");
}
