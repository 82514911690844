import React, { lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Layout from "./layouts/Layout";

const DeployInterface = lazy(
  () => import("./pages/DeployInterface" /* webpackChunkName: "home" */)
);
const Home = lazy(() => import("./pages/Home" /* webpackChunkName: "home" */));

const Browse = lazy(
  () => import("./pages/Browse" /* webpackChunkName: "home" */)
);

const Test = lazy(() => import("./pages/Test" /* webpackChunkName: "home" */));

const Page404 = lazy(
  () => import("./pages/Page404" /* webpackChunkName: "home" */)
);

const Routers = ({ children }: { children?: React.ReactNode }) => (
  <Router>
    {children}
    <Switch>
      <Route exact path="/">
        <Layout>
          <Home />
        </Layout>
      </Route>
      <Route exact path="/deploy">
        <Layout>
          <DeployInterface />
        </Layout>
      </Route>
      <Route exact path="/browse">
        <Layout>
          <Browse />
        </Layout>
      </Route>
      <Route exact path="/test">
        <Layout>
          <Test />
        </Layout>
      </Route>
      <Route>
        <Page404 />
      </Route>
    </Switch>
  </Router>
);
export default Routers;
