import CryptoJS from "crypto-js";
// the contract deployed by the artist
export const contractAddress = "0x3de1e7312Bc701B4b5Bd4C4C0854d690Fe0e7516";
// the master contract
export const masterAddress = "0xFA29d395ce6BB81F8cfc412840181Bfb82895bF6";
// the factory contract
export const factoryAddress = "0x61b98ACbfc23326Cfe296f380B5aa3e5Adcc5238";
// the artist address
export const artistAddress = "0xd2839E6d35EfAe479Bcc932fe2779f97a24177AF";
// id of the artist in the NINFA DB
export const artistId = 1361;

export const projectName = "TheFibsByCharlesai";
export const projectLink = "https://thefibsbycharlesai.ninfa.io";

// Factory 0x85904CC8c7864f7C45ceE002c19f57A2E6f0b759
//   ERC721Generative Master 0x53B70b2Ea3909EC418Fbd3DB3E96FcDb99ed1141

// the merkle root generated at the moment of the deployment
export const merkleRoot =
  "0x8d266a5552a40bb7e5cb14c0556fd54a4d22ef58eb0c6236558e9e5a42d55a8b";

// the allow list from wich the merkle tree is generated
export const allowList = [
  "0xa31A547758f695822fE582C76a6C404D5adC988F",
  "0x0605280F557eFC0547e0eee66bbfCc052294126a",
  "0x77E76aC6C1ED23E723947F7A2fE2F6c9c8347eC5",
  "0x1439b4d5a72343b68F12398C649dF99d76b2AF53",
  "0xA8357Ee17CB3ff5a6b9694DdC8FdE0ed2cE9d788",
  "0x5a8116f07937394e6Ac2A51995Ab6a054C08bF9e",
  "0xB7C5d9B62C233b5C9b450AA53A0a1d289fc0f378",
  "0xc7D178d2582A0889d3eD5926CA5Ac7C9d2A97317",
  "0xc09D1aA3a4Ded479FE9bF76cD4DDbe0C5279c6D2",
  "0xabF98CDe9966ABf4C968786331Dc1e3B8Ba5809d",
  "0x484e2Be931fB5e92fbA4F33E2fA0219eDFE61Ae4",
  "0xEF1a9ba46C36601F4E4FF647B4005197E800c73F",
  "0x7766db1521A3F2e0EAa6AD4F1557504468793f01",
  "0xe495E59047c84327C916aE55514e48c486Eb5Edf",
  "0xbFe85442265722181DecA9cbF0a4f49C63A63239",
  "0x169e99679e0016d085CE6502a737519d69893f27",
  "0xB81492d52C86D095fCb0E0cc3dd8dd74EBEE21B9",
  "0x38419Ec9cB0bcEfc8844C3C7F77474A300A85A3a",
  "0xAe238b0C6308EE1103C869511d18d28FE3434959",
  "0xefF04A7F8d4433d1dcc00ec9BEcb19c73212e3f2",
  "0xf3b79fbF0B93eF6F1A3Be8Dee40F97263Da7fF8c",
  "0xfD33eA42A8180376F2eB2F6eEFfCF7469e7D332F",
  "0x11f38bEb79704b87B6eE07207d9bC7157daee1Da",
  "0xa5C0A9b949760f2b8e11fbcE3B2E524d186B6974",
  "0xB0AE827e7a8C4B4eb71D4119C32A250333769364",
  "0xf1f5BF100D231C092e4401d0E7AD0f6FdF9AC2Ad",
  "0xf95CB821159FF932A24009E0B8e7c4D82120c15e",
  "0x6C4A6922254B40C15b69Fde2c605b9d26761E724",
  "0xa4C64B1be3306756751baeB25D8521E31605E33A",
  "0x0967f74Ae56486e43F9F930c277e80A76c7c6e68",
  "0xd6cA3fFe8F3Ce2553DdF779087eCc7A2Ba7F9f52",
  "0x723c6E8c5322674114ca701302a2229Dcd81DFE3",
  "0xd7097cFD0452B316Cc085017BFe49886dD66206d",
  "0xF6F5C3fA8C9ef5A243aF69a2E110B695AB96af3d",
  "0xc022cd6d5751914eb756C139Cf9700Db8a4657fc",
  "0xc420AD94307d4deE6927ffBD4f07758133e4B1Db",
  "0x98D60a6AFfdfB6F838EF96b9f49D4d7B8722AFc6",
  "0x9105824a276EaeC1F60a68b674AC732A02C67106",
  "0xD37905283aDf21d3E628B423cBE20c4583BA9979",
  "0x1d3319A33D3A2FD6B84Ad571f921C15ade593e9C",
  "0x0F535c627fb8255F4771082e7f1d0D18c8545260",
  "0xf3348CAD1BB96F5B8C72159B2dd2F92268D45CAE",
  "0x00aA85FE54fc51f47039F3045D632dD0Cd8f5660",
  "0x72E48F58a1e77abbC7757d60eBB7F94aD1730839",
  "0x4fB5Ae963c4fDa03D98Fc3ff8ff287FDF49dE548",
  "0xf548a8464609461a8eCe9279864D6bA339734084",
  "0x3b2C0d86d525F04B009931cfd67FB5219B1A0e8D",
  "0x0710F6bbB1FdEF63B79921A5a62Cf4bE76d67a2d",
  "0xA453B1E4b30B6119A7BD5b6B8BB1E6973A18B203",
  "0x768058a4b0054dc1cb025889B2eFD9C2051d2Bf6",
  "0x46fF0d7ceBa0A934f9786a5F051c0BcdB003D065",
  "0x047C4d7Af709ae19D3BBbD5F9E10461d2554dA00",
  "0xbF548ed06B604eBC3DBE2c0aC3271eF00dA72457",
  "0xb4bB21F96a5f8BA3FEB0Dde8495a4e312dcc356d",
  "0x2b5a3c0954395A98E8Bc8Da8b67eeb1aCBF5F17B",
  "0xF596248d8b3D78b87b4519f4ba6Bcf7f242A1bF2",
  "0x4d90A27893191953B7a94FC82094551d8aA1004A",
  "0x7D406dE3d4B2970DA41414f2024ac0FC87D41Ca5",
  "0x4CC33a47Fe1697b10A3d428a554dFEBc55fcAA92",
  "0xF267d1D053aC4356Dd6F9c52FCd775e59F54E842",
  "0x513e7f043E0b474B73DcD72e0Cbf96159e65fc95",
  "0x58239d2Cf593d9900946F644Bd1573F746103359",
  "0x8CD4E9A07BA23F731EcE0c79B6f9B369376708e4",
  "0xFAdb8039fc1d2F7e57F02B5d6fcF44529B1966CD",
  "0xE120edDd8A1FEA7D73AaD75d8eD8406988B2C98D",
  "0x456f6e34aC23EbF3478F3AdE23c7F633C81bD461",
  "0xFaf2702F09c52998ea6cff070595c2Fa06419277",
  "0xd7d1c6484E4B8B3c09C7b9Ca8722aa52b0046C12",
  "0x3430f112DCd0a5a2E9DF7F0C8732729b764af1b3",
  "0xE46C5A6652bA2958D9d732434A56794d77F74724",
  "0x4819951Ef30D768046212E705B92993a91E906D1",
  "0x5cD76e5992921D682A44b768C683F2aa5e36Ac79",
  "0x7a3010b00D9866C80cadaFECA573490e432BA3AC",
  "0x94d1121B1Fb7FBC325bA9AF7b48ABFa4B1480613",
  "0x9239f2BaD862052b1756C12b66edFD625072192b",
  "0x16F2bACF87654067e6b0559bC8fFC09f3Cf1C5D1",
  "0x3C02A8D11E3f7D530b7b494e124dE680f64C516c",
  "0x86b690D141341269fc3d034F12D6ba35ecCF28fa",
  "0xf839205e30419ca98223393e245Bb853A0D2Fade",
  "0x30F682b2b7a0e8C72714C0Ca4AD28B40ccEF3fDF",
  "0x86BDB768BbfE8DE39c658346Db93bB952cd9e526",
  "0x7FBD5935a40d8fCC57537B201Aa5e7b3fCc2Dd24",
  "0x8b5a1A96fa5A485A202B0797C26635B5Ad687c60",
  "0x3A3e0701d364e8e1A405d1ba0Ca732FB4dC114A3",
  "0x912064700cc53CA921992a739E4510f9E060eDcc",
  "0xf9f9AA8d55faf49689B712435145417eE685e0EC",
  "0xEA94DACE59eDe50C55d53cC90c26d8ec4Ff0B685",
  "0x04Cba99bf19958470d03BE77Fd1936C1AC73784D",
  "0xa128539Ad7DE7BAcBDD368B63E7a6aF4D2D414d9",
  "0x3E06B157A7c6FDFaEa072D54CD93307F48020cC4",
  "0xEe0B8fa174807D71B4a49818DC4f90585122050D",
  "0xF9eceAaD8F337df086D7D45559ff9330917C2650",
  "0x42A80b143e447586f3E635E6E4DC385F092a5C6C",
  "0x613a907f63C148F22C4F4cf6dfc1b2bb57892C49",
  "0x714e075198636956785B4cC08Be1C70d9361C445",
  "0xFb1bB9d4a4C50457D339A686212A0716EA0adC80",
  "0xD99ce37cdDb7B3221927FFDeac2b71f654eb404c",
  "0x49fbb9f90e78Dd1c0569816e1eEeaf2C28414ed7",
  "0x14669Df8BFcF56cA60dBC3397B8e8f0c0aD23062",
  "0xA7C4c76afC0ea8b370c2ccB6a66be947733A60d0",
  "0xC33fceF624699F9a64ab62491613a969434AF287",
  "0xCD6Cd394B8473b37bBC645d56482b82386f8ABAA",
  "0x8A46a1094450a79B4d67ACfFA235e3e5216F0609",
  "0xf33A686e5b288C787e236f4b720b448544458406",
  "0x311F2b05f8c9DacF4443DA9951443664368e726B",
  "0xE91A7012EC1C170021B9038DCCbdA484924c1354",
  "0x052D5C3a06AD80940447CA2692220ceFf5DF9952",
  "0x42C8740f524A68BcC77C7e5a8718f5dBb02a55Cd",
  "0xA02C7B8fa2790c9c559cDDAc698EB9A05FE6a693",
  "0xe39bD9e3AF2054Dd193B0D0217CFBD460f104902",
  "0xd70676Cfa67Abc65A8C39f049d62EEA30E3080CF",
  "0x0ef4db30F76bcbD1eE7DDBb056e699B69Dfb8eaE",
  "0x32d5536BdBeEa2512Dc29abC8c267dD7Eb554b3E",
  "0xd3FAc37E04A928f581459ea81D7B1e2dC64C2FBB",
  "0xd598e0Ccbbc94714422d544f1Caf162234C296bA",
  "0xD88695Da158F9215CF6730260357144c46f36D4C",
  "0xDe8c2f8d28c7Ef7D90394c58e7d4c91297EBbBBe",
  "0x160727e44213162C77523bBA90fdC13abA7789fF",
  "0xF4E8497a821F73c3b2a3720bF54C8c34Fa938DE5",
  "0x0d73AA5dE89B309798f9FcBbA8b86c4FD5f542fc",
  "0x95CeBfF4C387730EdE386dC4C8816F4d9EECA3Dd",
  "0x32D82C40374a86E3D041401D91c7a1F6aa11f7f5",
  "0xE9bc5f855682aCA47Def4A09fAB4dE5FA8b0FABE",
  "0xC2F500786E88e5ebBF6c34D5bA5A57d5DEfBeE02",
  "0xa4be1474A761bb0155F79C25233872e9bD139E29",
  "0x2e84e99B28199BAA2c166A3813E54Ef0cD81D92f",
  "0x04Cb51903a54f39abc45e3A0Df9BBC9dAFc5C674",
  "0x94B764bD9F21BACbA12487C4f67B6bAA7Be3a538",
  "0xAEbb1Ac559b767B848eA9645e86DFC99C8D0A69A",
  "0xbfF29b6cfD0c6BC845D4f70BDeeb3378a4AD39BF",
  "0x76a96f96255A7984dA77Cf36544F9206dF730fb2",
  "0xE55c9840eb6Ba1c75160Ed611E3C72Bc438dCA54",
  "0xf202CaED749D6B4818De46326D6Fc1547Bcd417d",
  "0x6E67eE72645E674A4BfD7d955930c3118Ee6Ce37",
  "0xBAa9223E4ffCe5aa5DEfD28cbfD057a333B25B09",
  "0x0ccab1b6df6E319d1F53FF041c9fc797939F6929",
  "0xC8CB314CcF997F69Cb8946Aba7fB05eC2404Db1E",
  "0xD5fe8DF39fDbdbdb55fba0e2078306656aD706cB",
  "0x885cb1217070bc7e05661a6B2a906Ce3d9cea705",
  "0x13a1DB3301fE0dd554aA4Cd4fDA4e27fa1f63Bba",
  "0xD4dA57Adf92c9020af0ee5e2787d013a6da4780A",
  "0x2B6fb56283e6C68EF3571138b18064A6039d2708",
  "0x88bbeD1616312e5867aaDA812Dc5e9D258223ac8",
  "0x66F3794178997a12779527B36a2F042e001625aF",
  "0x9D5262D3F5f59B0531Fa2EacCB4a10fA64C01da9",
  "0xf06c4e07cf3F6c942860A5671d6d6164Ce2761e2",
  "0xEcA9315AD26cA93384E824cefC609C8E63Bd7720",
  "0xb8695C162918B1199C3ac0C99795432c0041418D",
  "0x270e43F3249677c01dc1ec7DcBf7Bf93C8e8F666",
  "0xb0e13D3aD769232Cf6c8c03E62D459349A354fb8",
  "0xb8ec53CB2E44e4fD43d470162c2F5cAaD9662C19",
  "0xfD37bF58201f1EB6fBcB97d85b41ccb2D70D26E2",
  "0xB1813DeeaA45D5Bd4F988d370C98575020310127",
  "0x2b78e1252D487F7B2f7e6E37AAB84eE03Ec47bE3",
  "0xf3868dA3f0241F19fabB4C78a6EB171a28A3867F",
  "0xD54C7A903779BfafF1711799499f17B71ADfDFED",
  "0x0B516Edc029eC08075B7d4E0267f6D5015E2C342",
  "0x652747cb44D5fC52799c3DaEa613c52625588AB5",
  "0xe52B3182be73b3A8d60b45d51441a368b4E484F1",
  "0xb2e1462847E6244f9931915EA2294005643B4861",
  "0x569d15e3975AF6d1E251b7d55D2578C2f92CD33f",
  "0xfC2eE91D24Fd57Ab5D382C92818CcbCfeb854B56",
  "0x7EB6Dd4A0B074F405ac5D70aFd13C0d56CB497B7",
  "0xdf1fa21aaD71C50E642FcA3Aa4332da17BbEA409",
  "0xbE957A475844c127DDD207B4ff1F63900FD13E57",
  "0xf95a8f3dBf92EF2Ef95fA0c57dd9BD89D5574Cee",
  "0xA22c9a7C425e5e1a21684BF1B2c18C4c15e8d23d",
  "0x9d36eE50503D0928d213926C1ECbab626A36e4Af",
  "0xE3A804528409fa859B106347f6d4d39478Ad6DeF",
  "0xDBC29240c6434cEeca58f28d5D9e9402D9781E4d",
  "0x6882045f05687992EEe01B292B5B087eEa5Ad0ec",
  "0x3246d4884f31886C3523E927cdC25dF1F8873Db0",
  "0xa86B1ab5bc759FB0B7fc8611e1705688b747f487",
  "0x978c62Bcb2f16F7dEC4De9D0776264f3aaf8F7aB",
  "0x4B2a0281DD577e5767442688C1e84ab901A28D46",
  "0x7Da0abA834D9d4Fc98881b1c0cb6AD412254b926",
  "0x9B51F735d58D6FfeaaEC31ed5b5D0Ad881db6204",
  "0x4248d964346394a94277c5403981F8037c1E6D92",
  "0x5F91dDDF5822a7ed3950f85b3259511161B4E970",
  "0x9CC970649d772dd9710e52c9E928980d5959bE91",
  "0xC1923cAe3b5ff75c87A1CEfA8E80e2985E1232A8",
  "0x515299b1A2637181ec95a3EFEaa42A466195A40f",
  "0x6C9486f50545AE405ea6b882bdee105A5FB78459",
  "0x81B55FBe66C5FFbb8468328E924AF96a84438F14",
  "0x254F18b3D2bfeAE6931c2432c6dD34FCA16cB954",
  "0xF3998EcED96ae3cFe880aC9d2637B4118dA48E22",
  "0xAE71beeC18d616d5625D61d7932d6e681eFAEEA1",
  "0xcC28d9a4D9A2525f3FD3a3942D9910a3D1C89db0",
  "0xCD42401677bB6ED0c2Ac17d63A2823D6E299b3BA",
  "0xb0aBF7b22cf94FDAB991b8f17398a405a94701b8",
  "0xF32E1cFc6F012F9104C50ca3F6fA552F5641f798",
  "0x4A9A9883286400249686fCed2998a66b6740AF52",
  "0xd18eF68C7408Fa88937eC26E879D100F974B4f90",
  "0x64ee7b0C8e61E5D432D5ff464D28B0A3E173bc09",
  "0xD9c00b9cDB1d45CF265d1925eb1d004ee04D9f69",
  "0xEc8541d68a2f66690c2B45E8908Fde7E3B3e1bce",
  "0x6F10073D9154967B209F5c1cf578E42cd7A51b70",
  "0x87D61676Aa4c695D8E44c400126c2439c361578d",
  "0xCf52402AF809021BF4E485fc1bC9D97eDd148439",
  "0x90DFAC536F07F7D79C4e7eaCCb03a30792671cc9",
  "0x9f2F31d1d4cba2D61F457378EFD9F082307949eD",
  "0x7d461bB03E204ca5dd3eeFeaE6CAE9FEbd823054",
  "0xaBA3d119B49bf780202A26D658Cf34E6A4E2A566",
  "0xC4a843ddF533540474FDDb7a9a03342348f6742B",
  "0xc2FFE33ba5fc0D88a1661306e9C1AC2288B2F658",
  "0x487FE507Ab8b9ee898464C43534d0c4C5e1A98D9",
  "0x99B9Ce7Bc4878E5f54281aa24d0Bc65202b8C124",
  "0x14C862331538392584625A986536216cEbF08a44",
  "0xa61fe601D74F57C9aE2cBc550a6fAF98FCa59a0C",
  "0xF5771d44fb1899b255E24D050052284fab0A059f",
  "0x79fc8a000dDfeeb012a74C49a8f40954709E0649",
  "0xAE9BEF280440226523fc59F903b6F4c377015B73",
  "0x6d9ed472Da62B604eD479026185995889ae8f80e",
  "0xe2030794c0f0e54c231FCB674082914535193743",
  "0x3c640C85F94EaA33F06A9c6E334b67A7be9aF203",
  "0xfB27397bA139A54635cCcCdefF6eE39De90e3f37",
  "0x198559fAdf0552B2Cf2950C237c919AA48089A03",
  "0x3f3E791194fb13723B2Bfcd663057d4Ee157c30F",
  "0x46fb36aD95088C1b8CebFaA4b4CE8Ba9764f1faC",
  "0x806d2cBF53F196080C32189020801EC5e3F1a33e",
  "0xB340d9F239D101d8791ebe3ADd34675EBc184941",
  "0x3b04844f2D01eEC9c84F512fbA286F66aA01D9Cb",
  "0x50fC5751F76e56851E044FC2173AEEc636BB4d8E",
  "0xd32916E642174D8CCD6938b77AC2ba83D6C0CaCc",
  "0xCd0912478dd91429b1Cc613A2A4eAA7f0fBf0d23",
  "0xa7c025b22B43c6e06D4a28814A4A160E8317c093",
  "0xb4f2DEA715A6Ee100e79b59d49ad4f9eE37498bA",
  "0x507183c8B149F5B20F57114d25698ca1f4ec5ac7",
  "0x2546AfbA01F5415CE43B997a575D346a11F9691f",
  "0x5C17D378b9bBAC2a4d4e3D33716Ae1d4C9AE0f93",
  "0xc24D1151AA6C0C789e88b60DFF84e716aFAf0074",
  "0x29Acd44a3Ada661A75b5A79567510c41B94903D7",
  "0xe1D747cc1A91c37BC8af4943f3b92e0b0604b2E8",
  "0xb7973c6d5209b1bf5c198789830b67d5fE340dad",
  "0x94310B5d54c1F65C4a62E7CA1D73721145459dfA",
  "0x1cCd4FE35BdE55147F0fb17DadC8Dd3702705cd9",
  "0x597227b11e66c0E0B744cCae627Ff6fE868bD71D",
  "0x9C8ca5720bF5A39166828c33803A695C867B9550",
  "0x041481C6721c5A0291FDe527612d4C2d07ba5be9",
  "0x904B0ec1317f548a72a6DD0aBaeeFD5A3ab68938",
  "0xC4E7b579D1BE3c9E3A2151E54dc4B7124F148Fd8",
  "0x39da6ABEf019D86d7DB775758C92A99e00C7a985",
  "0x8D4E7eB94366799b39b60e934e0fA2Ae252716ED",
  "0x1AAEc918A3Da3aeD23b9FD907CF908E96fee563D",
  "0xA21731C02b5b563EC7d0C9d65c80B823f6232234",
  "0x217B1ee8ac918a09c543e2e9E781E2175cF48073",
  "0x8B8f754FC868E81092DD93424873781Aac234B0A",
  "0xb1bbf4fBca5Eef0Af34a344B810F7C4b15C666A3",
  "0x22bCB734fce13A16DD0619Bd1bc964F54289C4fC",
  "0x8E79742adB81ed3CE83C2478C77BB14bB0Ad0280",
  "0xD9FF18a25faAC68D50B56e402954773D159EfC27",
  "0x7928DC4Ed0bf505274F62f65fa4776fFf2C2207E",
  "0x5c6c461E4125019AB247F74fB519Db60cf92FAe5",
  "0x70354Af198ff9a6fE9E3C13084438f2bd7b731Af",
  "0xB11A3F8739dFD4f1993FB86FA305710BF60B3994",
  "0x4fEDEC285987F47EE09ffE01D70cC73711dd8e35",
  "0x993545Ecbfb2d0ef53eFCa9793d8c594413FEb8F",
  "0xb1CbB4218EAeB6B972D4122cA1aA38098E09aD20",
  "0x07c9B316DF787E40a307b05Fe8CF7cc4bb3694e3",
  "0xe9475CE97F2f80d8a436F4A220D20D4A9C37c7c0",
  "0x8822b78Da8573F940cE4dA67AC25793a4faE0E41",
  "0xc58E96Beb90e44B7CF28FB46EE02f33d11f788ea",
  "0xBe429eA9F2eE1044dc517922FdDAB91F22f9d6F2",
  "0xB8b8C5539396AD4b8A9b37D5eDA8632605238829",
  "0xcA3B41906c0d2785F62C33695e6438d910bB2B2d",
  "0xF79135d2662e064c1a2D0d747a15888f77CB537D",
  "0xd749C554940051FC89629616e245aeC3886BebE2",
  "0xdC9733b0B985D5B87Ab4d5a8f9848a2e4eE394C3",
  "0x3d4FbAC05963165cD00aa4F500dC77638F29359c",
  "0x9F22983d8aF03ceA1b1CECB2E5E2E9A8d513Ad4f",
  "0x663A3835ad2228B4D6B1A95A661ef274e38610A3",
  "0xB0481E4d9513924D3EeF075781794ea81b14e0F7",
  "0xFbd85f2E389ccc3CDF2006F16CF7813a5a0B3715",
  "0xD7fE1FAc2F93740F72C94D1911b1b7773722126b",
  "0x685b31Fab14A2aF3be0630366aB41f242910265e",
  "0x73Ca2a21cB4C2821c4F4439D9B3A3E303D5e2F05",
  "0x6d779596f095463362A825a67145d0c5245a89F2",
  "0x4398335d91CB76345865F51D35093B7762CaF034",
  "0x72a0E4cCBf3079D35a84EE5e39f9186E04A4917b",
  "0x1e06FDB842256f9CCe789d7c12E3c2b51B8D9f8a",
  "0x8bb9B5d417a7fFF837c501Ff87491047E1598104",
  "0x5A6dF655C4CAdc1f38957FD7Bda3ff5C6822eAC0",
  "0x76AB95C8ac7f74E09684Eb8Fe9FCF97Eb0d885C4",
  "0x11ca77a7ff3a14d992E70bf042f056485a40A4A6",
  "0xaaB86239606e1755abDe3C0cCe833340b2A88c72",
  "0xB34DEadDEf5B0ae4eA80ADf32d5D49C3b26b2CDe",
  "0xeC6a31412aeB209D138747Ff5c3313260E670057",
  "0x8BA7E34BE3E5601C14Ac5B75Bec2b24cB1BD37A5",
  "0x616e662D822f683B65a67b56aD19F0F4dB87260a",
  "0x9d75f97fbec58998ac6ab92FFD95b10a9Bd72E48",
  "0x27bad4cfF7F844C3743c0821199c40A9f8963EFB",
  "0x6bF25009F1329E0723f2a9205d136c7CE2878F64",
  "0x14A9134B3fEBfDBFb6357DFaD8b65e5c5383E7BD",
  "0x111DB55b2375d9EA4Dc2362BaC698da3a3144458",
  "0x511c4D6CCe87f564D12cD36F91fc18692523673E",
  "0x8a30436182805CdB0C69000803EAF84a60ccb814",
  "0x5c2260103bA960D23603a7b824c80a24EAe159b9",
  "0x60330C9De429C6f8bC3e4A5Ae6F74b6F9f776b26",
  "0xCd11770a3cc4c313d5844686F7aA5Ec4B29E7787",
  "0xf76a4C27643C0096f81c1CF775Df70Caf9fed399",
  "0x7f1954320434568f355067C9891a4161110caF0a",
  "0xC68304E439E04b0eA0C0C07A021a26bF708F7669",
  "0xee2a4c655EA792d92F12A3Caea21188be6dEa808",
  "0x24eaD79cE9FfAA883ab8A643C7e693bAd8F6875B",
  "0x747dbFBcfb74fD5fc115896c758a2FDFf581c702",
  "0x85615F0707Aa6BaB075c152c483e653e50A5190B",
  "0x312DC40670f3B173f871C44CB9708D765e4eD4bC",
  "0x265162AFc056f2c786769C41e6219e59c41cDc7e",
  "0x7D08C340fCfe051c462DCFDA8b9ED47642b96b10",
  "0xF32C74cA26465DCe91dF6Eed7021d6dC110E3BA5",
  "0x73c30bB094Be19bA325c59ee4A12Ff4437D41d34",
  "0x11F46FFf2624737Aa0D38507E761B6Ef770Eb94D",
  "0x96E32dd09Ff875fac038d41181cFbb2224a4573A",
  "0x2A26fb1A180a2cE5C6C2d06Dc4d430c37e7ECF39",
  "0xAb4273E7137F9531FCB47D558D9bab0e726E6937",
  "0xC1681754fc94B1856Db9C473D956dAaFB0c043F4",
  "0xc07F2785aDfDD4fFe9288a1ab77ed5e147aDe0bf",
  "0xf4810E5445d4D49a985846DeC2a3E9214949AfbC",
  "0xF63D67281A0Bd66aA790e38eFf50A71ce45Ff860",
  "0xfBC76261FD55cF91b81a97dbcc2B3F6118f2B935",
  "0xbE8Fe12B9EB1CA2a593e6C070c71c294b6FE9f00",
  "0x0458D795106c6FD8F087f996D8b9119B81b51bE5",
  "0xBB34d08c67373e54F066E6e7e6c77846fF7D2714",
  "0x046C8B874b6Baa7f37E29Bc09ADc7aaE23AD4B80",
  "0xc2a8527550A35a98Db426dc7F9933862538c945f",
  "0x3dF63758bbCf697F39022A2DD619765c0FB8949d",
  "0xEA1c7edF57C7302679807BD7dE1d21501bD61C03",
  "0x7Cad02C1E53deCF5d1102A4B9bee5de79c6D2E18",
  "0xF7F2475A0866ae4a8bB1c67B4E2277bD3319a101",
  "0x5ae067318Ee78351A997001D6c26A8f473D60622",
  "0xb7F73311a823FA70059Cf6e22A842C3BD64c53C3",
  "0x621cF04Ba3c8316CfCbB027C798B8F93d8f2Ec93",
  "0xB2d60143097b4F992BfBE955a22dbb2AcD9a8EAb",
  "0x6197508EaAdA410986efAc3c1BB0bFed92de40BA",
  "0x205798065bE2bf10Ba02A2303396b38beFDc3c06",
  "0xEF8112C9Ad3316dd179167d298426A0000A00a85",
  "0x6e0889CF9d108F4741dBD8FF35Df9Aa5B1cDc4a3",
  "0xC0efdd2b7616B1a469D5FC05dcc90f29647f03b2",
  "0x6524E9B964978b86Ad6AA5B11374864926f0cfa5",
  "0x819DFc1B757c0ED67fEa97e33E70cc4cC640F99D",
  "0x9b6E12B36508282D3e89A2f9Cd3104303c09B4FF",
  "0xd19286BdD1a2B06DF3c1105407c38511FFB87959",
  "0xdB5Cf3013A437cE5437b241B9F39b04f5D0bbb71",
  "0x30Dec07aF807a47c4C5a2bA69a22738831F002B8",
  "0xD9270Fd770B3E06f7b4A7452f531f035826A2eAc",
  "0x191d4C47676dAa5Abd35424F84f094F7e6a4EBb2",
  "0xf020cC5093C17829C7aE6b552d83D675fA271357",
  "0x48B94E0C7d882315e6C68718Db2e80E47ed99724",
  "0xDA7De4A782098167377f1F5c3370bDA10856db02",
  "0x5038100C83686A22bB78760D3793EA2CaCC27254",
  "0x5AD1592518EAdc34A22263fe5e56C19c46fFaB8b",
  "0xDf98322a314921fc6863806daE62a157081B0A5A",
  "0xf3D834c5C5189BB0B4119Dc7B366910Fdd6BC81C",
  "0x7a1d99c12C22FD8B64e62b1FbfC4677140f0Cf8f",
  "0x04D253FbDd4501C383e35dea2663a053B7439904",
  "0x2918cedA641716Fd6BdA3CcF691c3B54D4C87b6e",
  "0x1671ba83cE928F6df0C9973098dB430BdA4d214d",
  "0x4Dfb7B151504ACF52D5Fb225A0847e000f9039EB",
  "0xfD60F42814766aC5aA3CC5eE126fB78721f3026a",
  "0xAc104d56e4F8E0462f8C001eC7DDE4c68deb596F",
  "0xf16Bf1c992E8FF1011D87f7d48a55746F0966bca",
  "0x6EEf09B526d883F98762a7005FABD2c800DfCA44",
  "0xf3290df945E484A4Ff846fB5bAeb6D1147a7aa69",
  "0x8754E1eD406a72f8dECa5b4c9654Ad2Db698Ba83",
  "0x38F71605b54135435AFD641F276d118c12Ad0E9e",
  "0xd4e958293d5F88f03be4D56f1095CeD6576f3cb9",
  "0xE9B9BD9192C73Ebda2f6c6F2B6B26D1425d0170d",
  "0x0dfE9eC2F57C3b95653f47Da96B49861e4010B36",
  "0xD514088aBe24f9198a2CDDD04A964E2f5C5e1789",
  "0x373d419979862c6845e74cEE5AE1E075801b6abc",
  "0xC8b28B6A310904c83D128BE83b3797073b5C5302",
  "0x9BD91abdf39A167712B9F6c24d1c9805d5FB9242",
  "0xa3c3FE440e3C89aC6E563E056203AC44991d5Cac",
  "0xA370f1a9B0347DB9d38d8757CF43cfD291BB079D",
  "0x2e6803e20FC55714e7140f516823d2ae27a12c79",
  "0x6cC91dCBC8e4E20A5744d9A0737034Cf04629747",
  "0x931f5EDdDeE2f460f7f1c62f69f88C796cdaf834",
  "0xFf02b99BA450a7Cd3b906ed6Aa28Bb138800c4fC",
  "0xFAB304Ea4FBc151d055DF224Dd8f1781942DeEbd",
  "0x1986F4BCc6b78d40e499E928a910DD7bde857734",
  "0x7275a343DAe44518AfF56b44ec8C35904E208997",
  "0xA549487362B9BC4a66804fbf4774B2Cc26Ffd4Bb",
  "0xd39f6FAAd3349e0204f3618c7C609B5010AC46aD",
  "0xAc5914F52D77198d16B0Eaa6c976e48110ADce08",
  "0x4f3c0d8b6b17eA6438b46E14D9fcD6d056f4AA36",
  "0x9dd7604F02f9BA324F7CeA8F08E8a1945165be9f",
  "0x3183005B40EFf537939dc07E3fDc946E624bAA3F",
  "0x5E6A1899Bb32c968eaF3aD18fc1A2A5787ecf755",
  "0xe0b43c8426972F1B3113c0eE0Ea835539Ec8D3dc",
  "0xaaa63EC7898a7D52840AD38e58d3a0624db34800",
  "0xc70215BB6ebb45fB4EDD7DAcE92b884277Cd4Ea3",
  "0xe3b84986472f7FeA21e7f7B447456160b304D2Ed",
  "0xFEd93F0Ad93fF25993274D8245a2bB2133de38C0",
  "0xCf1320fD381bFDfc9Ce76Cbe9806aAb1871244b4",
  "0x9c026639DC7e3498fa6e3375aAe284d75A19d94d",
  "0x7002aCE0d564e67F5Ca5c8D8e85dD2a21BB55563",
  "0x2151943f25dC5B62d18E88E810a2Fc7f990043c2",
  "0xbe0eFa0a364BA096edab4eFC0b2BCc3294648fF5",
  "0xeDA089739e7110819e680896a1edF752f2532fB0",
  "0x44b59Da883A9cd1129E2e6fDBEfC1F94e22c11C7",
  "0xa6b86cFF41251cc85A33f784C9Ea61cf011372D1",
  "0x0bAe2FD474615C52233c001A072F3e10553e26F7",
  "0xc643C9411a6B489e9833b16631140F42bBfCb6d1",
  "0x6A171Ff0b5EA7Edfa2b4ee9385A4f888F8BB8e02",
  "0x9fC10Fa9a7a47eAC3Bd17e50F2B8534c88a127f8",
  "0x88d5EB1993dD04bf2175f940e64fD49A90D13F8b",
  "0x848b6f0aA41B673eF4e55AF5a80891D76bD80ca6",
  "0x0C959BA0B71DA4A6C2663105cC87363ab251ec8A",
  "0xb3Ca8922b9a709cEB8033064Bb5eF1B7C92348c0",
  "0x5F3F6dD893A49ba95a215AEdb221Db144973AdB5",
  "0x82bef40B2f6Ec44C6F5E8967d43F46f73EDF86a9",
  "0x23a650462C3502A03e58192c9253437705140f8B",
  "0x50b88F3f1Ea8948731c6af4C0EBfDEf1beccD6Fa",
  "0xb2c3590e38D8aD0Aa3aD96f838FfF1b71ef201Eb",
  "0xe7202dC27470e6D57989670c29f5655Df34369A3",
  "0xDa309D3c6e0C3c58406EcfbC0D09b813dc60bCa6",
  "0x1223e13Ff2316d55EFc0a67fb53b80F1cf9DeE70",
  "0x84e5334b68f651116E6182A6BC2F1902B36fe68E",
  "0xc4C8CF8E9b28A75C0eFa77003Ac3D6a8E27bcae5",
  "0xD48Bb125982A8aF33c78E842c7cb8431904DCBd2",
  "0xEcC8d116BB9F59CfA49fd3d5D5F54c34562A65b5",
  "0x1Ec3C1f70E1D6bBDC84092ae86eAaDE495fdDB9b",
  "0xd2B12f1a75b2313980d7c410002e6a48cF389b8D",
  "0x646aE8D95575561fBD03F2490122d8Fb2F4f41E2",
  "0x2FddD73757000a0028372A8803729F2eEE000e18",
  "0x26a1e46832bd5ffd7D21481D185FC258A7080316",
  "0x5FCd863b665E91e0cDabCc636905FB4D516eFb0e",
  "0x8B7Ae9Db845ff3EAe47fE1070224f62E12B2aea9",
  "0xCB4de7DB08B4dD0A9954fcFA0A09762F58436df0",
  "0x3d38D0aa67F760BC3e26e1Da3419865B594BE35c",
  "0x81dcAB1513d88478a3536348d6d7560f2d8762a4",
  "0xFb39a75697b375daBA7bF3B109C31c4A271ABAD6",
  "0xB011733AE92D0de2B9f4A6c2Bb938eD3aE7A61c5",
  "0x667bEdb9211dBE0DD20d4f00B51682be1E3f41ed",
  "0x67C759D1b7E26C1BDD0fB8471248e95676e123A2",
  "0xF2fC60b88137a9f22bbb5ED0B9310401aDE39479",
  "0xE92Db645018bfDD8622deE559AC43f85054370D9",
  "0x0CaBab448FF897EdeF2013C6F7da320E83e9F2C3",
  "0xb7e3Ca9C6Dc5f56b4494bC3D1780356650e93C68",
  "0xd90ceBceb3c7C43B9266fc3d0c2083BBf942B79e",
  "0x852548338cd5A8DE1384FdE3bFc678A8669C0F4d",
  "0xf4AE92d4E484c7916Cd351C6031AbBb05a0E3a6c",
  "0x55c06d471a6F04B3933947Ce0aF9211B85f3cf82",
  "0x36Db231C21B9161266D53Aa96b9a0009A3437907",
  "0x38b8ad0864E0E55805EA166E69C39ff4ec7C04d2",
  "0x0852E995BeA500e7D9f0A955601f9A3396D2670A",
  "0x3c6FDf1dBA9181095Cb294490B5D9eEf0679F8a6",
  "0x5a015B81B58AbBd18D0832FF7361C3B4B72B07E9",
  "0xAf92BC8c73913E95a98f63Fb8Eb57B5F8227A820",
  "0xB1aF13b2E4Be55cA69829A23C09a1f5663Adf8D0",
  "0xb1bFd5873A33Bb2d052E724808A87534fD9d4153",
  "0x0e3c735708f660B9D29b1F05679c13F72E14C0EA",
  "0x70FF537A22312615A16E1FFeF022c6b49B86d0Ea",
  "0xf520aAf89eEa21fc9366B3CF3cD9206F0a24bdbb",
  "0x78086Ad810f8F99A0B6c92a9A6c8857d3c665622",
  "0x204F53280d005bf28ED06F71cB7bC9fb4187977c",
  "0x6a5fE081DD22C9C0807f9fABE657C42d001F2d72",
  "0xB48e45c76E7442D9944790085399E26b7d89b1Ed",
  "0x26A875dF5A179e648eb1ab6c5AdCD6C3c8C84c87",
  "0xafBCC39f474baf9596C1135522810d5f409DDE0F",
  "0x1Cec7876d9f9A776A8358D953CE21A9ce37BecD8",
  "0x27B304669B1AD32240c8DF45fa95De85c28f2d2e",
  "0x655442965667D9569015f850463224Cd6A895771",
  "0xf033618C0eeEB9342641A227C17D76c799866F52",
  "0x7Aa1349Fe07826F3F6C9238F75DCE46F4D8bd33c",
  "0x85e8505b9C139e48a7Bb1E20cDaA9efA9379A68f",
  "0xB754303AE99772284B441F3aa550bC61fcBb431e",
  "0xa7Dcc417c63F24F9073b667A5d7149bD38463d0F",
  "0x874df22cD88aB1Ee0192f48F37879DFa93a8305c",
  "0x4dc583548a3407a5c5F6468Ccc964523cB14E9d1",
  "0x891AA27Af08e3bc78e00f239355e7D9b646B0A25",
  "0x22F057B5189D796E9B56159774A701b563280B2C",
  "0xe4100C6331483D4dD192E30F240E86aE58B287B7",
  "0x0ac0240f4656dc80684d0df1208Bb91c0220725D",
  "0x35b7b7C40de5cFF2F89A6648b88c1C9c0257742c",
  "0x549418604D15df787eb24ca47f1F02fCF1600A52",
  "0x2f71F0b0E5b7FC1b4F0Ebd478d6B006A41A599B2",
  "0x4e7B8d5DFBEF53FAcA35BbB2400785e93A6Ef5aA",
  "0x7Dd47De272D09FcF3cE22eB30B3b39836a92AD06",
  "0x43C9359e1f35D07C26f2c469790dAE5C906394Fe",
  "0x5254fE0E46cD57faf210F1F06ea6a5248FEAeeaF",
  "0xA31c87C9498Ca42bd404DEf70114342d19ad342a",
  "0x93C730265D9B4E5F3216E799aD1619928bE5423c",
  "0xCF5988CeDfc2cbE576FD486EDeAEe513bf6F1fEa",
  "0x5133135153964675f9fd2eD6D234572129af3a87",
  "0x25F7De0240ECcd80d1b743F2e1Bf5bE2A9cAA3A2",
  "0x8BD8Eb535858d39d8EDb677E2e422Ec54d6519f1",
  "0xE681e5232da0c9bA5d28E6775bB3b6945eAF50F4",
  "0x4eF99a6642c18f83353b567F64Ce92a6E64Bb3a5",
  "0xf612257a9BFc71bafbB9C1d27ca52Ba834875ba7",
  "0x96cd55CDd63F1034591b11A9003eAf16e7fe715d",
  "0xf019A36720dD869ec7653906dAffC3C32a04cc66",
  "0x24C3833219AB8128644F37cbb51546cdfbCE6c02",
  "0x481B8A878A91d1b475F91EC6Edc98341678c4406",
  "0x80Aa186F646FA560495c4E965AA983B958A47369",
  "0xa1ca0291C438a6Fe476E2B6E171ec807aB4663DE",
  "0x6fB6A5ee61462F244FA1fAD57735B4825a6F33cC",
  "0xbE98AD0E66829688f01d84fC38e720E48b8ed1a5",
  "0x3E0914Fc2B3B67A476142020B7d6990D01aD234e",
  "0x56B238eE0a4e2f8dEeDD5Ac789a3E63af6b07681",
  "0x60180e058f00434D837AFEAcD50B0AeDDfD7e46D",
  "0xB94480DAd90c3B41a9C24d88f78533973e18Fa75",
  "0x7D68C0ABB48dAE6e5FE24Dd06446f6b1332dd2cf",
  "0x68bcA5a8BDebe05Fb8A6648C7316b4Eb7e19a064",
  "0xdE4e52A342f5E01001aFE0Bb5dF8725a0f543aBb",
  "0x92aEbBcB31A62b83bc96A9691Da2D6D6f0F1cE85",
  "0x50F27CdB650879A41fb07038bF2B818845c20e17",
  "0x5fbe799De139710A2EB644f0410Fdffbd5F189D3",
  "0x5C4FE960950BA0E09A72869C3D51fe70f07580e0",
  "0xFA53981b7E4F55A0590e88A72232BA843FE8d08D",
  "0x5E03b07aE157EE1CA5BF2F3CBE5BC8dc38F3e965",
  "0xf02fE5b5F57B0D75e4588919299f96F10da07f14",
  "0x0Fdc322aa9e9e614D1A0b1bef60FF708aE464E23",
  "0x9F6431646CC0657F3f5c2f3b2D70119661FA259b",
  "0x530c9B95C1e0354f3582D3e5ce90208a762C0d3A",
  "0x092cd1A6d222a167F5D0767e6444C8b45C92cc72",
  "0x9F04E6c3beE2deF65557847e9a7eD2CA902e45F7",
  "0xceAe4CA7D082856d140f3672780029d6E90C3dcD",
  "0xf8986Ce1FA0A656ee8D7e306c9182Db06C6d6449",
  "0xf87ef29CC0c86fD5335Db58d4fF113d6b354e323",
  "0x6DCe0C9DC00fb34B5e1E932E1D640884f5f1782A",
  "0x2c4fCbBa39A4e15A04376CFb4Fc971BB7583b6dC",
  "0xeA333A3134D52598DAf4D5976cD2A4468F517dAE",
  "0x475226B2A2B68401ED0472761ce8D4CDC09d2999",
  "0x482293E4c21130f069169E5d7E9c99f239C5eE8E",
  "0x5f1E60850EAE2D000887cBfd3117BadB342E19aA",
  "0x707c2091462411054104db2f8D07e38A88BfC7Cc",
  "0xaA63ed7b52bc0c03e4C53aF307069C98530B3b94",
  "0x3cd378C9B1cB5f147eBF1b2c2564118946AE4ba1",
  "0xC1CCF7456fd640473c383468df2c18a2e3D68EA5",
  "0x8E01D001A2a328E8B5b16520196E539cD2C37Cf5",
  "0xD921B982A9CB40C59831727B67E17766a9D387C0",
  "0x3b5Ca475aC4D82433AE91F281951Fa1825DeD3E2",
  "0xcC0Ba34FfE6107D4119FCa34dde7B09386bC8166",
  "0x5E1c2d64212b3ace37B8233Fd890A6067E55182d",
  "0xFa34804390a2f6e14C547aFa976d4479F5f660E2",
  "0x9Af4389da5aF204Bfcbf7A2130f7Be7d9FAf10DC",
  "0xfCF080D18E260a01246Fd5c6E8a893a7Def4D369",
  "0xD12e5d05CD8b23c9821C57e648e294c25f004810",
  "0x1E96B3D06C7DcCc94f122F435D989A3EfAe84fF4",
  "0x1307001ffd264Dcef256EA0f7826fda218153F3e",
  "0xCB90DD8a79Be00CA9ab62284978cf881c2899346",
  "0x92f4D4B2d9B85394C9E30006E9d0CbC0604b5FCD",
  "0xB3eB9E97B5e6b7ECb50C7850D8b9A2b934b79c04",
  "0x4949B46633b810bDd745b028062b30F6B647EC60",
  "0x35D2FE3970c871a6148A2f9112C4F0CcC1048633",
  "0x9720dbe3F2716EA6FBf56Ab4469b902e4aCAA182",
  "0x81901BC827bAE61D14b780A5a9fa9921a4519c58",
  "0x0f5C4502BfA1E366b47880B3181C051380f89c0B",
  "0x3d45ec9d2fc1450079fE107E49ce5377E61FadD8",
  "0x478bb542f7658d635ABBA67edB987806DFF5b83d",
  "0xf96f784097CC43194f811F56F93c5b8a06dfd902",
  "0x58F628c4e33741dbd9749d24336916e54E0dD403",
  "0x10469a0096279403B9F87680f4A75578Fc14616f",
  "0xb4d8d59c51e052f64d05F76CAf731A4f774010FD",
  "0x2bB73277Cb2ef27862654341Fcc69baf058A07D8",
  "0xa9195ed97C78cE059ABDF1F90A38c0BD47df3948",
  "0x41eB20B6E38649761049BD7eedD9066b2C074d7C",
  "0xa7650E468993e41F32E506E9D74BE8998937ED6D",
  "0x133776560CDFD26d3c1BFcAbFc888cD567451337",
  "0x35a958858D46Bcd9984360911BBaAE44F4Ef8f50",
  "0x9E00B0B219D5C2612a61fC66B9d864B375f6d90D",
  "0x8798e94c1991c62abe1F2B050C258E4AaD9f9951",
  "0xbB73f76B26873A56cEdF2734090c1D3571b378b6",
  "0x0Cd05018932688CA4aE65f4D3A6223d506cf3793",
  "0x0c61d3d099c6a2FC6AD9f0EffEe4BefA8a0D29DF",
  "0xECc953EFBd82D7Dea4aa0F7Bc3329Ea615e0CfF2",
  "0x85A30Ede5E46029457e20D55427eE8673FaD7762",
  "0x4E7e1C73C116649c1C684acB6ec98bAc4FbB4ef6",
  "0x6b216A545Ba93fCEC51e2ce255f6248F57Ea980D",
  "0x332739AB37cA55FeE20B9Af275B090c9e58f7482",
  "0xDb2BcEE1Cf4e620E2339139C1f7F827Ca12B42d1",
  "0xA92a45eE4E527e2F0AAeeDd6360E7Ec7C3AD9C6d",
  "0x5F73B065c8412b43E3db33caD0EFc5038040A597",
  "0x0998fc77b101E4C538F57bc7616c5ecd77dEab5A",
  "0x11C03b0E147af0e527A7f8BdFEc94987Fc5C579D",
  "0x5116174e599F07FEF4b56B4B03a7f68e3f2e5c70",
  "0xb4D235b16a456E0609f03393cf852cA5D7838CD3",
  "0x3923472BFd751609f00F5dDeD944a6A77524208f",
  "0xDa3863583c9FCd8A5D3506D91AAF33157124d1d1",
  "0xB722e190319010D6E412509b582a77Dfd75A7858",
  "0x22Bd04906E808196B19F7d10463fE463Ed5f88CB",
  "0x9fAE8562Dc4fbCdD9D26d6D10a7fB88ce60A028e",
  "0x4CD4B014F0442a87564FCC7845202cE2C624ac3A",
  "0x9E2712Ff48390Da22f0833C9C01C21E449654812",
  "0x7DC4e09a175a687cBe4C2B282a356F5f63A17848",
  "0x31C72d0D03aDc76456D3C14a4Fe19f8AA8307C16",
  "0x1FB576cE36f72b056b456554622335e9dcCD6f3F",
  "0xc5c9Cfe3E5771B3E5A57a62C2141C58F2a3749C8",
  "0xb726cdEF8a817CF42a045D8DfEC6Dd4d76Df2432",
  "0x938a0aF4B86057489bC651Dd02C080890d8ed5e5",
  "0x880c8fB6A59fB13Ee7894b4Fc18b7cBB529ED24E",
  "0x3e1F1fc109B0130a8ba13a1546C713D030B8613f",
  "0xf1DaFB1Ca027E69b67952e8a385E7f43791f2E70",
  "0x870C383239D06a2c440419019556C968e06f4627",
  "0x0fb39DfD1B192bBB04E0E95C844329041B48F11d",
  "0x8F54249ae4c8A73E92D44459e026c9197670F3fD",
  "0x54F1AB7EFAAC69dFe6c7c35a8088E15337128a02",
  "0x13A7d2Fb3F73B57EF539C35C4Ec59Ed49b1833cA",
  "0x7b360D0c4aeC613a84d09AF2d9294F55D76C5CFe",
  "0xFBA206E73709F614E5a85afF27A98692d4F3c579",
  "0x2ce64f21DcEbb47B08375E62D75c098EAd3C1Cb4",
  "0xDb6f6AB07E266596b97459a570cCA06f0BFCA9eA",
  "0x983b0bF79af51dF81e4E7dA165d0b6F6335Dc5B2",
  "0xE831E57a808C0321DF5a3c6D3454641239eEdAd9",
  "0x04814c18C03C7B40f991d1545c44426383D75b6e",
  "0xeAdDBaE2651E21B4ae261ffBFCc81970854C4ef6",
  "0x6AC8EcEBC610e2ADD9AAB5Afb16D9e27711e603d",
  "0x695A45B7b6f74165faeC3684C25F77f3E4e0928C",
  "0xf2439241881964006369c0e2377D45F3740f48a0",
  "0x9Df3294fDF33Eadf6480338d6f6085ebd5ae97BE",
  "0xf2Ad36B91A231Efd6f3E33F8a56D8563A5885dd4",
  "0x75aadBb7c36Db057E5aC64183899F118dE76Df35",
  "0x656432938B22656F717D4e479713527bf6B7f00d",
  "0xc373cBFea6ED709bE353582B263542A66619A5fe",
  "0x6F91216bbD310892AB80c8F635A4b343e6176E79",
  "0x6F95F5eB03496765742b3dBeaBd885d605946AF0",
  "0x5849734d2E3adA23B64e12311Adfa6Bcd6FE687C",
  "0xd1Afbe4dA2A5aDC6fAf30fCBeA9E4EEa0Ba8C70a",
  "0x53Bc776b4FA5CB94f9206Cc4537b8c561F1a1b39",
  "0x4f68ebfa7e430460F5A4117426A82514E3Bb0535",
  "0x72Cc766074A0394744B7dA382CDE96A5cA9DFEbf",
  "0x09D57F361f794D150C04443A7B6e369120DC2bea",
  "0x44f829ccbdC9c15cA84D4FD3f00C4682C35f228e",
  "0xe7B57196268e2B63C7B7e064A42B46c78b3dFF98",
  "0x060fB9f0d0529A80f5fB94EcAd98Df7df99Ecb0a",
  "0xab3973ECa21870866087866BC7CA8804aFA002ca",
  "0xDc7bFC3058654da90692457f68ee3a34e2dd3908",
  "0x05576913eea5d79B83f28f0Cb0D12BE54Fdae8dC",
  "0xc8240D39426406Be31364865b0472Fc7a0e8Bb01",
  "0xAF2FAEfdDC07a5d3D3F822F57558060cFb286A54",
  "0xCc72D9E9525b55297C03C2d09B9677617A02D156",
  "0x9424116b9D61d04B678C5E5EddF8499f88ED9ADE",
  "0x23E709a657EC46F6817646c386F89E993C9a6828",
  "0x44cb86A104d9c31bf98f8c7b7463b1A11239Da36",
  "0xBF8e282496C00ab366f62082d06208eb61162ff0",
  "0x61c888e1AEB86420D7cCB15705Fa192D19FBf363",
  "0x7A2aF8b20345F59BA1f119E7CC1A1Dc185AA031B",
  "0xeC6E5577CE4A3882049A4F93b0dec3BbB8B1cAD9",
  "0xe01A97FC4607A388a41626D5e039FDEcbfC6ACd9",
  "0x2F9eB05F0C01F62E8b5ff5e72Ab4F821b0F956AE",
  "0x13189e189f7a25dD85b32F327d65a3511BB8e8E1",
  "0x6b611D278233CB7ca76FD5C08579c3337C01e577",
  "0x881DE4a2392a1Ba9925A0f3668Dd266550c0D055",
  "0x500bCBbe0ff8Ee8FE7c50F7606650E39A50cFD4e",
  "0x1E2fA044A25bCA1576eD94562Db50F51dCc24cA8",
  "0x520Cf8cC90cC100CC6d30F23350B376820730ea7",
  "0xCD432C8F076b740Cb7Be693a18983218588D96Ca",
  "0xFD7256eCf4537EB6A372866ACB4DdF1A7535392a",
  "0xDdB7a9327025D79dAE4de47Ff01cc6aaD4995A43",
  "0x1a41ec571EC8Fc815FEbF81FCBaf9D7F7eC98849",
  "0x3A88bc828083eaDEA53F87520250e092B72350f2",
  "0x3A661a18CB0258119636DfDDe098648c6ad5bA62",
  "0x02d61Cc47Cc9a19b2a023c33c094269D9aF07862",
  "0xaB94f597B94f45680B07997C394535D7eBC4A297",
  "0x5C0CA497298EDBc92657204e41C3C13aC31687c7",
  "0x43Ea401Aa472655aE81bd926AAAF3719082ecF31",
  "0x2168B2295E912A27c0015BaD9F09F090EBcE1a99",
  "0xA2ED517EefC5365b05A970622aF0f01586453510",
  "0x5efdB6D8c798c2c2Bea5b1961982a5944F92a5C1",
  "0x4d0856AcB3cF4C422Ae47F4DDD32368aa4BE4a1F",
  "0xF76a3C1965b15FA7B109BC7ce18d64246DAE6d72",
  "0x473C923543d9c91209B087f456d4A796B52EF96C",
  "0x8Fc4E14c1c2Ab50BE594208b0E5f1A22056316d3",
  "0x14739b2B9CA2dbfE90dD86085EB958940AD0221C",
  "0xE968a888792b3c6ca4242a87445e2518F88F2e02",
  "0x0937D3f1BBC14F1f079D4F095028F57c4E4B0722",
  "0xC6C2414d4AB18668704340F25F2227F08142d69C",
  "0x3fD61B4ee8709161B6acEaee75fAd700A7B4c974",
  "0x0CcD0460528C1d0d76a8cC892649b5e5C2326638",
  "0xd7fE95C58F8FcC7A762c7713653630E601f3b44f",
  "0x14a281535F2F5828d695F1Fde223e58c6a12b8d7",
  "0x85e5514FC67B6a2c228330cDcAE543783a440947",
  "0xDF8465e364C5Ba32bDB44D83B302Bd163622A263",
  "0x8544a547366eBfA8711ccF60667Cbf7c8b0943f1",
  "0xBa7c54BD0B7CAdEe4F5FdE32F76dE5117ED2d0eb",
  "0xf09dbd55a893a8Ac50A1F0015F7Dc161c594F4fB",
  "0x48Ce37136C6050b39eFac72725089a32Cc3aD053",
  "0xA7b32f0c55BfE631A9470a6b3312a6685Ffd9f45",
  "0x54127ea13b843eC96828df4d53BB8B6296fD809D",
  "0xe869Ea56398b4309F6d1a45e948f259b9c2A7AEd",
  "0x132dF84e9EA2f4CcA42976B4ce1A8E814E5CB809",
  "0xdCBEB312b6c38B8583C119CB29938B464196a35e",
  "0x8C36aaa8db6112f97386aE1e742355812B8Ede08",
  "0xd76dFe29F0371Fb0640906c699165b6bAB33C522",
  "0x39180E128a042B3EE234b9594EDc5EE6bB874EC7",
  "0x44Ed32B27AeBf445c209DAb3EA7E3a9D50F37B2A",
  "0xD2305c208623d5b93699019B55bc4e356B8775b5",
  "0xb48355DDe0a555E4CDA44D673eB263cb1884E7ed",
  "0x75fAFc81466f40e4C4C686437882219353C7d0f3",
  "0xC0b0Ef40a9a1afd52cB1500cB81e26F9C1D4A1B1",
  "0x2c858619755dD175EA81E587CE0103C0D6D785ba",
  "0x10cFd6263d33428B9A87adA54284d6a5dccc1080",
  "0x0374159C4aBF2d7db45cBa753fCF7FBe17eFA1A7",
  "0x876aF19A9DA8641AeCb2D545A3E3523DA4dD1974",
  "0xa815A2856c02756e3769fa16678701693dE623dd",
  "0xce14E6Fca816EF32B6D484C3901d994f870F3947",
  "0x21E21631862fce7748FD2cb1A6F0B7a032aE6eB0",
  "0x9e4f2BF1d32aDc25d650e0b7f7cAe04D861721E5",
  "0x0Ca37139Eb2A45cDc3A2160aE96ee423143FAB9E",
  "0x5a22873100426833f6CCFd7081Af3a354F3DbeEc",
  "0x4F22eCE70A13F472C80345cA4eC3D1B280EFBC09",
  "0x71cFE5951e236A1d45Fa668Df460558772527638",
  "0xc2f6a21319621F019d586E38c3E63cf25F754a41",
  "0x52e24D260A4D0737238f08fE1bf29ea19608A299",
  "0x5925f5d1D9190794254D1F9D5c7f5fD8068Bb78F",
  "0xA55737BabBAef04079eEEe3037eCd48571C3004B",
  "0xa592d6B05D364F68Bb375463c10e2727dd70a97A",
  "0xa0AC51e27084f1C9CaFa7265B5D4cb0836Fafa10",
  "0x0952B72b7a23C0f8E7AE1927685f56B33A7B8FfF",
  "0x9B49607DAc38750107aB7D8e625Ff61fb0A84cA3",
  "0x00f5c86974c703429a2FecfE56B9F1f62141BE30",
  "0xd034eDE0c7E184D0F133B2b81467d76CD492c90a",
  "0x0E0e072051ae3bec3fE4Cc6a9f765d20BD7f55eC",
  "0x77F979c54b760d86854f781E4b3a231F642F9711",
  "0x69F557C8777A568e8d447406798117978BFe26B3",
  "0x720b72aeb5aB52b41Cc7760416963d19D31B982a",
  "0xFe6437E6E4D9d91f34434c39351BC0D6C699958A",
  "0x5a307f507e3b2DCAbb0516D57A77789d73F2a086",
  "0xB645e2a9f38623119160603F5b201Be07E9D87ED",
  "0x309Eb19704f4a5EDFBfF7B0829C27F645bAF0087",
  "0x5aDb28Df0E6F76Ff3c574725A23d627f4E1fAd67",
  "0xc7A673d7BB75376a0ccA6aa5A54D4d31fAbF29c2",
  "0x1aB840c5B19D84B65e79080Ca2E6ef11f5C80542",
  "0xDdeDdfF25a18e98b0A0CB14B0840Ff75953Ec1Cc",
  "0x0196f4b747Fa5Ee7B655A6479fcbdd5647b867C6",
  "0x646e56A0b795B9C44a49e7E79B8D34F8c6d00934",
  "0xf28c80D6f33d14F04189F6aDbD935bd81713AAB8",
  "0xbBb06Cbf8B14473Dbf565f3f93f5f6182327653a",
  "0xf8f5e13923042BF3C2cD67eEB5F5F75534Bf7570",
  "0x02Bcc4c4a74395505A097c85fa7aBa006b61a945",
  "0x6bad7EB81d78744F6EaC53314221B64F36DC35ED",
  "0x0573298124c0886fffB5E0d6Ce1B6B4BFe34061b",
  "0x187196838a3B058FeB90d68cCB7105Fa3A595b57",
  "0x07738C9F7bCeE718D2765eC6d1d23deC486c4cE3",
  "0x30e3306a3d7f788C0A2E38C73413eEff28719194",
  "0x75A7F1d6b3a419FE55902f5b7edC8b5C92155913",
  "0xa882De58dC0d717Bf5c92701768daCD9010BdeC3",
  "0xe14139A6e6a49c6175dBF8f26ee07f1208EE14dd",
  "0x24a61BAce013fD590837ea45188AD0cAA19606DF",
  "0x91Ac79e22CFEC58E586323EEc052d4a19C74BDCc",
  "0x1f9724f3054D8f9FD28349067F796E1491d7d1C9",
  "0xCE63b511AC3855B7d5A0c92c965Fd62B9aE4c0E3",
  "0xAF43691Ef8b52dC27c75c57C05371Dba1FaBe378",
  "0x4D566ebC6F28358C6f97c3629b541e3b8399989C",
  "0x5aE2b8e7AAb54dc0Ffd571c30f76b2d8Cc9fC1aC",
  "0xe73739C857524d09708152489370dFBBebD6585A",
  "0x020aF56A3633C98f884616d378BEA83045D86c66",
  "0x9623F57a707a85989Af30680Dad73B63cFB92687",
  "0x319982b8c4dd935Eea2bF9c73b07Fa1e89e34E88",
  "0xCf8b87483F98bb24Fd54e534e7fA7d28F2dA1515",
  "0x7a35a82eC21812559A016AaE24Ced68D0193100B",
  "0x7f7743fD3f74CF0c7d0400d79e6c1C0c52626618",
  "0xDEe0Dd0fab05987A21722cD167EbE0036D96269f",
  "0xc0604AF74eBd2a822F218285Aa231fb97676E2B6",
  "0x85df2D477bAd719b3AaA858f184f121fC904a25c",
  "0xE1642Acc045617CB2f74A6837b41AB1F7960c0c2",
  "0x8D01d11a8D0EeB7B60d6998c6602d7043AcE6e55",
  "0x456d8e04C67fd2638cC0D84BCFb67d32B6459678",
  "0x2d68b06f5Bc6ec67440734a2ee36f48A98a3FC15",
  "0x93349594De22F947f66dF0168810B7eD71A8f4Ac",
  "0xF2aEb2E49cA3Bd2ff618b9F19688b07D3c1928a0",
  "0x951360e606587AB33EbcAD952593b2FC99D68c3a",
  "0x64d18532F07855920aB624de8d2A8A365F365e5b",
  "0xdBb4BFD34AA4f7d62ACa6C92Ed2bE99a76059aD6",
  "0xEad79E5CEAd9f5c1DF0090F393CE36CA000a7F74",
  "0xf4ed4FE99943513Ea6E70ff576986311f416127c",
  "0x389D43178ad6076521C7F2Ca19bEEc806ef00D2a",
  "0x1b9a7287916A904b05B699fd0e07bE0714CFDDC7",
  "0x1e6e8642897441A90605c02Ed26b3C1F9e274c07",
  "0x73DF6b159a12d9f53e65a3E443fECf22185185b3",
  "0xaf519b61005aD57f8A9e47f4A4db601225064348",
  "0x1A943b5762fE096dDDD3545DeE8D90543CE56ef1",
  "0x916FC013A1d0e8255EC5A1a64ACDc3007b7b859F",
  "0x949567228ECaDaDb68D6389190f830E475659a55",
  "0x2F075618681D45458aE20E17ca3CCf1C797d6E1a",
  "0x642716149cA2FF036071B6441F8E6E6a171b99eF",
  "0x10D9fc004962a84d9ea981Cc79D9A8107b234808",
  "0x229789541806cF3A2805Ec271791C226620d2D5F",
  "0x768E8456901EBf960Dfc6b39FC4D442a2e7CF462",
  "0x34F4C9D0aCeB6Cfee6E6B4dF8ad069Ea4a4BefB2",
  "0xC17dcFb1C2A4E8B1Cde280aFE837E0a63AfAE268",
  "0xbe13b47282870A0253D895Aa135aCA347C5e66BA",
  "0x49a1A1c04Da3179676d415ebbA784D4493C79a2B",
  "0x03A317E1Ff0176D464be99641398B8098fFef4d7",
  "0xa040B775Cc42c3Be9202B25D6420784579f4F979",
  "0x08792A1765C94268b112f398E035E6255275dc39",
  "0xb721640F923f9Eac62032097cBd916501A3D3E0F",
  "0x533Ebd01E7cB0712891C0d0a3A6ed74A447Cc538",
  "0x47Ded080A4EF933e96E357d468363A8A0cac03f0",
  "0x2B8066F42a26BcD1aab8c96df1d0c7De1316100D",
  "0x0d00F05b44de65B44Aa475b7Db6Ca4960fe0c962",
  "0xBB0eC33671Df1AD41b0aFa6aC3032AbEF8c8ae51",
  "0x3a5e247429201d70FAe0fF28e41fd8A1CA50AF5A",
  "0xb5512Af9996539e7CA2387Ea1722d2C5EeA72dA9",
  "0x872930E5DC25F210Afe6dC5420616dF9d570cb92",
  "0x92FC95e1c0B4f8E98fFA44c5A8E624D072b8d96D",
  "0xf41C94fdc811EC4D6ba8e933B56cC264bA1A4BAF",
  "0xb7Dd10D8A3ACF57f05262C67FAb46A9a7898100e",
  "0x52756f109536d8a7C95e4F442a383c10965D3dd6",
  "0x2910553429caBaD45B22ab18c03A6E2e1116e7eC",
  "0x13069d6d6a14D3B5F99f7a55EbFe71d1205EDD58",
  "0xA23b0DfbCdd17825c04B6B2ae4abe80AfeD3044c",
  "0xAd4F4852329443E8CADAFB1B5f6362C0cFC1B639",
  "0x06A07a40899Aa9857784502a64fC09027f6D9988",
  "0x589AD95db08979d176ff66e2880B185680967b55",
  "0x6A3809C6EA601bcC83Bc4903321aB01E1467835A",
  "0xd7b86bD17192a573C45cb4797cD5D728618e387b",
  "0x734b5cE55f0E08bA02d1956EEF689d20fbDCD4DF",
  "0x71aC739B0f4FEd93432b53060F1Fe402c0be681f",
  "0x675DA94D0eFE96cfEc1cC6Bd6FA11702eBe14249",
  "0x2d267EBE430fcd8607528a10AF16A27987461D11",
  "0x9C0269Cd956E12031C30D6b0b7C305e62d74E6C5",
  "0xB922b87F1f30735Fbe68066F94C438B26bc76A65",
  "0x5145A5DF9aF1DfF058CB4AF82123a4eafD5aC981",
  "0x67eEDC05587A6Ee61C47965009bfb26Bc767acB9",
  "0xB08337CC7610F28279a939ce0a25ad6AD64C28f3",
  "0x1CAa16bfAD2bF70fd59F9Cb063dA4a3a873D3C5d",
  "0x4Fd4c35020CFd14f167BC1D888eb903D7A650768",
  "0x10EE6468F5D970cFb3CDB4E0866E9816B4e09e3c",
  "0xaF998A6d01aEbedaE7e2BCb4eb03ada844243ae5",
  "0x39F4011a501536dB284dFcdD28f9706faD00A8Cd",
  "0x6D336a136F80121513f6B755Ac85fF811d5C457e",
  "0x23B60De9500218c67B83C8d7662Ca55D85F25306",
  "0xa89F52874836555C0dC5e183E4d1c6F04C0a3F07",
  "0xb7ed805384AF1Cc308506Ae497011835F26618b4",
  "0xFF13F9b23a16a82c3Ccdd29EcE6af667EEb15834",
  "0x06A2DdCaBb58B941866829ef0BbAaF85B684a478",
  "0x9408a12f1d254554378e26049357794384f2e953",
  "0x7C0919715392bD97B30457B8dDAe3E21B2789F38",
  "0x5972A0B205881659aa8cbF287FCa3F3BdfD33E36",
  "0xCCC31EF402AfD22f638337aa26240A5B2D0C872f",
  "0xC6eaA69C6f5541388122154297834B94c68dbDD1",
  "0x5c087D45f975cb190A33A40f130c9d4bBF0a3ae8",
  "0x1641964AED9635aB1F8ecE9294142D6Ab4FF81A5",
  "0x51547B8e4180e1Fc7883DeEc19E19CB6B58595b7",
  "0x7AAa95EE9fb4eC3bb8B9f95BDCB3eaf50342C40E",
  "0x7394843B233738969A15294F8EC815cf7bB0349c",
  "0xee4ca6A4B97D6f0C21015eab980e62d4a574b327",
  "0x9975e5E8109eB358c76bB634665dB1299E736587",
  "0xC79676de862B404ca3D30f6713615e83776455a8",
  "0x90198C48129Ac7564933f8Bab85669e303FF6B9F",
  "0x45337BBA8D670EDAB50C1a023E085Ae6CCEeC92a",
  "0x3f7d7b5Cd2Bdf70639cd62246cB4adc27b7Be92a",
  "0x94CC64700A555685b758A11Eb64CcF16743Df8A6",
  "0x24dAe5283EFb066da9b07011b2d4C8AC5e5C0f87",
  "0x16e98E94b9330dD6fedaaC175e84A3f25f6f6aE7",
  "0x57E89F2b668D082De73Ea8934215E49caFee0469",
  "0x1A49096Ba1A258B51f7E60C703dCf799f0959cf8",
  "0x6d0208C3aD0c77FA34114897cD993764C2e74a54",
  "0x1059c7e69C743eD1cdd10380FD3127536D5eAfC3",
  "0x15F3aC83c4205a3fE053416aD0234847f6a990Cd",
  "0x5D4766F521f348b92fD614B0Cc18778217d8cB50",
  "0x583C3D9e5Fd01Bd3471D1CEB5F27737f36933080",
  "0x2730FEF18DcC74018439C70c9FBfc52b1D76fd3b",
  "0x70d6eB2B3b9a2a7D6D67c3f24c246BFFC05b48Ca",
  "0xbBF0F0efBc26e2DBd5B1805Cf44858668a9DDdb6",
  "0xa7CF46b95D0c81d13C1f22722Da6fFB7c72339Cc",
  "0x0ec65CFdf55d7944400709A9c5A8Da451937D23E",
  "0x8293Fdecb932552d843dCE9ba84C5adC0421466E",
  "0xBed9968753C54116140Dc6654dd273Aa077C04f7",
  "0xF4A12bC4596E1c3e19D512F76325B52D72D375CF",
  "0x63FC745B5309cE72921ED6dF48D4eAdddEB55f27",
  "0xbE719C273b4B2B180d282106DAE0E8C917489a27",
  "0xe9C07198f36e4e7283537F9d60C758B574E7344D",
  "0xe952D58Fc674d0CFB0B95F51488b180E54e5330F",
  "0xBe93d1b4a1B4defe63A95e48F560b9f6a8CBEc27",
  "0x2277ba6046c5d50A85544E68332FBd359a24BeC4",
  "0xeb6973c998e62c42A07316Eb4Fbc7b5008D6cF17",
  "0x065094AF6F8E06c0f0530BcF7D589e4D17608Dfd",
  "0x3543538abE754A5edbb3c97736CAe4B30A77e0bA",
  "0xe9fdacb027dB5D50551E8078ba71ef504bb0E064",
  "0xc818aB7E7F54C32d243cd7Edc469b60CF13B22D5",
  "0x206E2628D04C16533e4C5b3DfCb420FF15a52cb1",
  "0xc734fce96D3DD328FD8bED5922CDeE54BA3beeb6",
  "0xcD746c0accB25b0BCc6AE74994Ab2dB7d0CF3EFa",
  "0xcA0cde5831F6b5A68CA9c5c1E13426793e25D205",
  "0x8B9060599939DC03Fea61B6E207472FB8D0B8E02",
  "0x4557fe43b413e5C400D320c4724fD4FBDDfe9d81",
  "0xF95Ea2E425f3346204280476864E6b1d9bAa4B2B",
  "0xb0EFE2250FC2744c05cD739C93a43155d2e0876f",
  "0x10482C8ea95fc94c3315aa5B21b2674DDF11B943",
  "0x78D9998f31A94E125756b106cCa79E1daE6A6014",
  "0x82B882C9C76a7A4571F5064366632Fd89841d525",
  "0xDc37e6f67BFb7361e25731fC477903e172D211f1",
  "0xb113caf2E538c013D11BFCD5c1A96248Cfb7D929",
  "0x4Eb8a34D631cBf724a48d5701b57BAD24C1E4779",
  "0x99d8D9a3c90b94b8A09C9292615E086B22EF4Fd5",
  "0x79fF435184674986312275a031999D689be9d104",
  "0xa29aD7CC2fE7cCd79805914c1a983aA3a0176D09",
  "0x298b8bD664349C6f86d2d4C36c81336eeeaF41a0",
  "0x63648f2c3e96B0f548575FC1B1EfcA1A61f5De50",
  "0xE14B40c23F0974550E810841ab9cf5B1b378748D",
  "0x0319461b92aEE555a169A5448727B8389618CAfe",
  "0x840F5D0fA8Ac1F000287DFE54C97D4E76b058EF1",
  "0x2dC54105d0341088BD9226A7777eB8D2813Bd113",
  "0x5E1304c8ED0F9C1C0Ca857Cab98De1962758E0C8",
  "0x2fB150e4dA26C7B34C50660207d09367480a77a1",
  "0xD1C6d8127312BA8715dB4A666e7cAb1AF898abfE",
  "0x7E522B3FfBDd7e9f058919a57A81eB861e00Cf8D",
  "0x4D31d3C096455c39c4cDF3D774F362A3CC46Cc1c",
  "0x9c3bd9dC1569379C926a11c4153063Fcd152Fc07",
  "0xabe9ACCE140a2D338A5df5F08b6dC87366e34d31",
  "0x5eE1d9F8eF343eD9bfcA83b7213C9b384FAfc4C7",
  "0x158f25e42485952f063D4f5724339D4cEd376432",
  "0xBDC3e5b342B78bae722B86A6d442b92701dc7Ddd",
  "0x12F9d54D6F0574F98009FbE971ABe2360B2F579e",
  "0x9951eCa4D195B5185123Cb3606949e2973bd90d8",
  "0x051f458eb3A16c982F6ea3B91eF822a0aD9c3A46",
  "0x8A2a8F2Bd05Bd04e2ab24e903F4B24E199906297",
  "0xBDC46FD302b0372E118b0f41e6A66411148473EE",
  "0xc0B1A85255bB74C4b5D7A17af1305f29623D0b54",
  "0x39140700d32FeF43257f654687ddbb288893d806",
  "0xBa221bb0A7B9050E78007D7275F91897E650dAAA",
  "0x11d3502407D152147ba2ca2cFe78Fa8dc8E5ef70",
  "0xF84731f15E3420924fcbA991c9d41A3A670757Dc",
  "0xD7C0e4A17B1BD94635Eba2145f048C5e7B6E8fC4",
  "0x155c18fCdc5f593728C5d5D158509271835DecD5",
  "0xDd30bC2d51596030d0E969670Dd6037e61ce43c0",
  "0x4660e9CBE586a7EC727E076b5593EAb9C51b5e4C",
  "0x3dB9Ae7D5341249e15bD7D02F248A2377520d365",
  "0x2769D7395A2a4014Be8E72484147810776e2f4B4",
  "0xb8f6bF63C17F33555c370eF3D125f048BD5ab015",
  "0x0971FCB069Ecde4eB4a43325E46041AAb73F5732",
  "0x5E222E726636613C40A87412b9ee04fF53c536E5",
  "0x854a8a5fc02Cf0472061e5f9Bf909FdE28fC5F0E",
  "0x229598bdceb88f58A6EdfcF9E1e1b8c2B185589B",
  "0x8E8D647440fb97F214be7B610eF916bE0948375a",
  "0x454eE605b0E070145FadFcE49B072b029099078D",
  "0xcD838D81Ab746f8ba65C239e01d195136D5a3819",
  "0x4271fF14388AaE8027C422DBE282067977e8f7E8",
  "0x0523dBFA086483164Bb773C920E6a868a5CD40A1",
  "0x47E80916AE6D19cB84F222F73D829a308b1F6354",
  "0xd8150E48739848F54EECF59B4d43b14ee901F562",
  "0xBfd6C441737d9a516CFA1D8305844F53cCCD73ec",
  "0xcC21ABF00A0c589C9818e6Ac438D8759ca5e611D",
  "0xc9aac7E22e08DDD52698c37Df409338Be3338951",
  "0xb68f14521e129D469B172064Fd4f3C0fC46A1644",
  "0x6759a65c878E2bD30031464855A7d956f7876339",
  "0x5944ec0DDE0cf3F7E7d8CFFCc04cb760CfBFb583",
  "0x513014C917E8F37A77BbD1d79ea5701AF5474eaB",
  "0x0B2910cc5eeDE0d727Edca73a5E60e921554456B",
  "0x0cE576C539bE4b38b6cFB5c56d2cf115ca90f97d",
  "0xa48B470fB1f9A386d88AAf6Aa47577E46ef1a54f",
  "0x3098C04B75c7fECBA9c7A80Fc24C097995e95681",
  "0x2e670198F899f588576aF752150BF1e1174B07Ca",
  "0xB45763C75556484af461e00F0F3e0954ED60b25b",
  "0xdAE7239B66c039529086B1D7b0BAF217b1c271AE",
  "0x2F2eAa4859a512274DdC03a253c671270ECb8cc5",
  "0x688D97023329D17027DdE76BA4d42eF11e8ee46a",
  "0xdF40E0AA962ff5FBa41BA5881e8Ca7e1C9eec543",
  "0x56aa95EBCf7659c156a24Bb061f2aB0E044e0892",
  "0x3877B4c39476E3Af04cB1FF8003Db09f39F068f9",
  "0xA3E1e0Bd090E79C98234ea90063C6432c7110A56",
  "0xf13D74764f00BcA46713FEA783AAc8AAC492fA08",
  "0xBB441F783f7d37557dB17fB8b6D38c588fbe2C91",
  "0xa5956a25Ac05B45c30002BfbB0586640370C6730",
  "0x7091D6Cb3e3497664e29B0022d516eaeb6D9ffE6",
  "0xF06beD3f0DAd7932d8D00fe48C36751f5C10be23",
  "0xB99172161B5eDFB4e01f7eE5FEb4264d684541f1",
  "0x48f0597F60523d36F1fbC2fE9F795a079Cef6d3C",
  "0x385Fcbd4FdaA1aE35263141BA39b90f305016AF4",
  "0xbAED105aF8c00f5A512DDbe79d66e834707857aB",
  "0xBbE4D222860aA189031299F8aF312FB4A8d4ffd8",
  "0x19fEdef56411bb49AFaf65f7E4Be50629be17632",
  "0x497056B9E7832eeAdb26C80724Eb79D7AD0ca77D",
  "0x03E51CC7381cF0C50f838Bb8edf74EA74A4CbE55",
  "0x03ac3F8431fEd36Fe51007fC69F27ca4734dcDe2",
  "0xcaA031b8cA07f5Eab6a90ED5240719AaE1f5b5c1",
  "0x3B446ddB0e031558b38F1355D27dE560b382141B",
  "0x6772e726DfF4Ad2541C859b0799E8c18C6e2C489",
  "0x4C2A7a992A1474DF18012AD1D79b3376e9051e8f",
  "0x5E83a805E75C0Dfe244ccf667f2FC22adfCf165d",
  "0x972272ccc520BaE6852f9c5ABC396E1392B4321f",
  "0x92AC860E0B916766E9301B48D45781B7341F5614",
  "0xe744D23107c9C98df5311ff8C1C8637Ec3ECF9F3",
  "0x10ef21D150adE22572F441216a8946b016381EdB",
  "0x25fC4D1AA94883fa2C10BA1583Cd154Ba0A73007",
  "0xeA87d49cC404D3f2F453CB2DBDC445c7dE43946e",
  "0xe803AAd78e6eAbCde6f820D2C64cF83402Eddbe2",
  "0xeA0d658b1B74fEa191712c738DE56fe414Ab6B39",
  "0x9b4D094799BC5b2247b4d0c0f65099F1b5FB4f71",
  "0x3457078185b6188401C4D322F3D0B626C21d9087",
  "0x5fC13C5A541041f8fa12fab492D064435eB8d2F5",
  "0x97A5eE3ff560f06eDc2B88c51dCE92C45d809897",
  "0xd2839E6d35EfAe479Bcc932fe2779f97a24177AF",
  "0xa7eccF5c6a2B1bE67A1d9F117927113cBe7106FA",
  "0xDeDa18b7Ec8be16328484F76E92BC1e5A3809A92",
  "0x5d3eEDB58Dc825099DF818A2ee381faB78f4dF94",
  "0x021E2F27Ee3a0ffa6Bd08a971624979340C5977D",
  "0x9E910AF21B2E04E35bE5957D287e847c21a67763",
  "0x7A506a78f052cD036E87BCd50392f7c2ef01c21C",
  "0xe4BAc4Bc44f1a97c781BF9de9C06124BCc05Ee39",
  "0x7297c296745846DbD98B58ED84B6b42D470Bd357",
  "0xef29193df37b60B443E09C62Bb2b239208EcD6aC",
  "0xA286e0C0fd25bBEADc028F1C073769bDD665A08a",
  "0xF0581beA7a63b930C4e11E67d746b207e4326Cd4",
  "0x1388aE7b87bdF98369D28C747642049D0078055D",
  "0x45EBb547ff2c4C13ff5fBc309b893A2b7da74718",
  "0x2133f9dc56dD85bb3f0Fcd3E25ABaeeFFFe6c862",
  "0xF5fDA09990a3cC9809A2B87Ec5B1b081C02B8e33",
  "0x06cc86506E45941dEFCf9b8339EB6C55D038464B",
  "0x420FE6199a0612006809DC0830d3F8cC80349088",
  "0x8b720D445CB9607De7804326D2A22d2e432F2056",
  "0x0503bF05c49F96faaC2B0f5fD394672eD4603C52",
  "0x16C93Ec97512832bA4244CC69527530D358db0E5",
  "0x4C8bC5dD54BD78a2792B578bD2fc919196E64D77",
  "0x5e4aEeE3013b94b10a7Faca0C637C96f4d3410c9",
  "0x0Cf54D5740F6F87184771Baf00c9338072Fc4cEe",
  "0xc9de93950545435dF20F340fFc7CC71be497F1e3",
  "0x9254faD610D5AF7c2AF34532f41DD8c0f9C6871A",
  "0x3c6Df4B329B9faF209B763B6Cf3E2a6017cAcAfa",
  "0x53ed40c04d7bF1a908e0c57E0a889b85e534Ea4E",
  "0x7037F550B8176f4794e667C13516dC6A25f5C75A",
  "0x04714eb0015Fced099a07977A6669F423a5f42e1",
  "0x6bC05B9ACB6273Be5A77A309532038729F5077b5",
  "0x4762b5Ac03fC097a500b48CfeFFFC027E8B37227",
  "0x15F89dC0088F13FFABBC75FF3F279c9570a69c33",
  "0x65A92e44d58Ed6e4049e157f1D99beE98bF595F7",
  "0xfE99460B27Bd6D4a3e8ADB29bdf038be6684dD77",
  "0x68dB7d1F16E2E353Bd4c4F9C3d12AeD15ABeB021",
  "0x5b822A27D35cDD2Cc2f00b53277399d4147a4ba4",
  "0xfEe91bCa027E2D74b656e2ae825619F61CB26736",
  "0x6925b22105664506D2d7e449B9cEdE78cBb1eCfE",
  "0x0208DaB46D3df9dBb1fC331E85b5D890fD0Bb350",
  "0xe32cBe7B5D7416bD432514c1bbe36A930B1d2dAC",
  "0x8Ac1f3974159989ddf421484BAc4603B3FA1df1c",
  "0xC49D2210568549898a636412a9801559B828DE83",
  "0xD614F79B1b01D42beD92EB05B58f973Be9a3Dd3D",
  "0x432D71bA14D2602B566dD9e3e098E24859d166c9",
  "0xA73b66AF2ee0c0E2c7C6392ed6352Ad0d3b05e33",
  "0x4fab00E016c28B8Cab90cb45Dc1A52B21f909ab0",
  "0x0e97bCB60145F6a6b56aDC31250a9E0a0de6c813",
  "0x1eCeC73639f8d1D720B80c0BCf93418c5F4204c6",
  "0x7Ac0e701E97cF220E9e7C55Ede50a14A2d919426",
  "0xdE5ca3F71255563AFE1B6a6EE9dF6e4d0079E33F",
  "0xeC3442FEc2842b3cB8C231c31745Ae107302407e",
  "0xe859726D1FE6F7691c903736062c40B6B6B5dc11",
  "0xefe2941dc33e8A114d009d932EfFE380DCf15E8C",
  "0xCaF07631229bdF0751D90fB55F6Bd9339dDb327c",
  "0x8B08342019BCD7cE71228Ac59176D24244C5DB52",
  "0x74a4783d95BfC338805A4995A8aDDa3aa45925e7",
  "0x751080E41db1Ab5382AC8E60729a3a8Eaf223ea0",
  "0xbA41C0B6C449fae362928e700aBe76548325d826",
  "0x356A61A2fBDECae55E609549c173939Bb6009837",
  "0x342A6D3e01C8AC2ce6Bcbb03907e8923567a59fc",
  "0xb5A4E4CB6AAA9f6eA1e00444251682E0f20f86Ea",
  "0x1248465Aa48A049290351ec3391FB3A65c0020f3",
  "0x01ac2f415510312198b562845b44F3C17e825F75",
  "0xE348768C96D97B245167eAE29bB793c7c5e5F340",
  "0x458D1427e763af0F9669e75dAB3019c9AF34098f",
  "0x1DC814b5def304FeEc68548772176264A9DF98BB",
  "0xb357328ead4bc0F155ae505c0c02b7E418cba23f",
  "0xbf4D9b2fe9D602451bdF5523F4C697e0a1E5fE3c",
  "0xb5E46f550e8974392450bE4225da0dF6a3731ee2",
  "0x4e1CccC39869B879b47Cf9eFC88eFC1885d3319d",
  "0x2C7Ba77e6F1D25EE8f4905D4cD6934E47ba19BcA",
  "0x47d544473819299B2b7f579b27E10220268eaD1D",
  "0x0b0Ef7d9d87fDB2dEba35fcCBbF6Fc444FF1C449",
  "0xabC4B9Fe79dFEd0508B6993B9347B97C85d9Ff48",
  "0x8A35A312c9FC415b7Ab61d0CCe684f19a2dD99e0",
  "0x741245a34B0481bE1046AcB2869fd9d06aC46e6A",
  "0x4D1baaa788e24E25aE4f4642b4Cc6EcEa95F5535",
  "0xC44924b9a4668bE9977e43dbe4cf7Ea5FEfaB89e",
  "0xa407399b4975bd7f0b802991eFA9Fed90E9d7649",
  "0x75DC3D4EeafE592A73DDaC50cF0EA1f5F545ef1D",
  "0x2d497ee9e6fC40BC4C5f8f8c0e364DD5fC7c84B2",
  "0xe3A6f6025d361b75ac0F95B5E3820f12B357dEee",
  "0xEAc5fa1091Ed13B12961a3A8fEF275d2D7E70b15",
  "0x9E8FdFC3D41B26A2870C293DC7E1be8675FA8b7b",
  "0x63e0E1312A693a3E113574060C424d1411ab4d55",
  "0xfb40034905B34753b7d74223e496518D43548336",
  "0x536403976C1CC6ae962299Cff6D5D6f74D4793Da",
  "0x2638F91804b32190622ef82c6a265D8e9dae57A6",
  "0x24A0435DD391A6962d8d99CE19f51f2b540412b5",
  "0x564beAbDc1afc0751f1721890856Ad4C5E644840",
  "0x05d2E466B841fe014e1Ce2d1F826707b15F8914d",
  "0xBE25E3C60B4969de23fb10930043E44F987CBc1b",
  "0x5Ed09fCAae951B074077Ca16f8b6cd927Cf321d6",
  "0xc73b17C5624512Bd487bDFFa928B192833b19fF9",
  "0xed71a9D034182e5a311Fc86eEbC7A4Ce5e0f2134",
  "0x8A8C7B64E9d1454F93cdB0C1c313f56858C96422",
  "0xB82Db479B41c4B8211c2B3ABD80ceedB9c6E5a33",
  "0xF510B0dC2cF246EB2Cb20DBf7aE7807470058f1C",
  "0xd6f2788D420282d8e817922b5Ff674981bD8D081",
  "0xfEAc6fd5BA458CAb7B8597Bc8510b39C67cDe3e1",
  "0xB5292BB264b19eb7fAfd054236ee2F2eE4509d8E",
  "0x7dd51B0247B288CB17BA8a581eD33aEF6271F04D",
  "0x63be81f380D82134b71ca8663142E8Dc63f7C8a8",
  "0x5ea8c95A12bbfee9BC7C581fB320A086bEa97A94",
  "0x878243917B0049453398976D897273c072DEaC91",
  "0x0D71Ec30c323037BDac7bD174d4a95cEC5A4eE0d",
  "0x81CEe9f5f817Ef1C1d5dd430C9c4efEe48F072A7",
  "0xc909865FD044077cB3877B44161AE9e6fDA66518",
  "0x323E6bC721bf81A5292a9C91acCdb4315c3F1ec6",
  "0x66F1EC0B82452B5b11438BEE5409A0D5A8381659",
  "0x6468AD8B58B01B296FB75b88dFc0a2271f05A437",
  "0x13dE03714B08B47EaC23dD617056e79be5371742",
  "0x5DF046603bAF0bd83Cef806d71774CA2Ca17888f",
  "0xaB5d4C82F35fAa1B10D526c7A744aE2eBe713A94",
  "0x26fEDF273B15fDe42810234F2233910307752675",
  "0x865eE57Cc200D496495178Cf8aE449026b9b3A4E",
  "0xd3Fe95F7c987C660Ce474Bc0866e48DB13e28F1A",
  "0x471318DE977E7A9114DEd2A2673E49eAF71F8310",
  "0x1A6506d490a2E125a8f5a5BEbdBc3341Db80DE37",
  "0xf3B492Ff2437F197faBf3A91F17530bc33221CcF",
  "0xe85f832D9F551cF445A6E8CEAaEE73ef88a340d6",
  "0x0b2095Ed0377F44e7Ee71883469A2983b8EbC96c",
  "0x4F42ebF84360eFcad0A392B795357342c94364CB",
  "0xAD514a4089812F8d3F3f8Ea5aB1c99088D4e9419",
  "0x907e9FDFD7807141220396Ac5AdA017ef8Aad229",
  "0xc90F44CBC3A8EeB198c564245eb2B853090039f3",
  "0x890AdFE8226a61C65b85905CB331C5c57FF3AB01",
  "0x172157f397b5683F943F12169D553f462FA34632",
  "0xB7611b1D09193a9E1dE20406d8cD6ABAB91DD29d",
  "0xbcdeCb8Ea27e3d6F77141B8cc722cDE0510aC49c",
  "0x1f9ddE7b25C87C97583D5771De13D60375Aa3907",
  "0xBDc557701d3A404C5a3f2F72Af1D817c40d89C0F",
  "0x53f6aa9c47AB6aAA4713c4392176E1AaF7352DC6",
  "0x7B4569B0429FaC8312AdCb0bddA606E9BEb1ACf8",
  "0xafa4C76ab13Da41EB9975F52A6bC4159BeF9FB97",
  "0x4fd9328511776B0A182b402d244A395e1E1841fb",
  "0xae20f0454cc764619489087FB7eF4c3e890062A0",
  "0x0007b9c4D506C6bE7B4A3D454d078Db5899172dA",
  "0x3A069a6a9aBd3c5A2473a2867DF65a41952d5213",
  "0x0c7545411B342fF722159ca116be24B7cC08fa8E",
  "0x9404432308865Fc5e7E736344B1a15BEb160b7f2",
  "0xB700feA0E530F280b69373E951F2B060e92a06B2",
  "0x2DCABB2C13899773BfF77E969aad93EB0E5be9c7",
  "0x56E6B5dE6a4E680E49bF1DE3e14B2D49E51103FF",
  "0x32C60Bf76818ff92453025f30DD21a7E44b709FA",
  "0x9b848370417C9f525d9168f2Aa91A2Cd819240E8",
  "0xde1Fc59F45582CCFe608Cb2f94A8212ab969DeF4",
  "0xE9110C571F6A4f846AB119329c054fda34912042",
  "0x264FB9ED5553017Beaa709E6410364aCd8F0deD0",
  "0x36758c62c604ffAa7822B9C9Bf4c6cCB61406f24",
  "0x1c0C531442d0A7C0CED2DBda9Ea02f43A0c0CcC2",
  "0x757A21fBD39cccAFF681e1930E273B9aDD0008db",
  "0x1855DC40D62113691192e36df291dF1B2823B8A2",
  "0xc475a351ed8DD2654f8D6F1b93890fF3F037A9bD",
  "0x97119Dc1F2586e7c1637e5fFAbf6458C94da7d93",
  "0x69083E9CF68dA3A2fEdec4E987c452C2ab790635",
  "0xB14289984581c3D8CDc24663F5D0d6035eEd642c",
  "0x573BC03f18F41b200b949A973d67b453a7EF11A4",
  "0xD6AaCF8BFa0f11deb571d4F6e38f7aE2E07cB385",
  "0x38af20Bb60DEb8C3C220088F7789BcaCfFf463ca",
  "0x98F58234db66F99C65C51cbE86271Dbf7b44d12F",
  "0x955BD0023Ed4d0DBe2108797f422C5CB91E2E32f",
  "0xbc9e1EA79124a06B0e2C0A79820F3FF711B6E58b",
  "0x288d7d7AEc3c5340D71821aEd49E68e9D3C0508B",
  "0x0773fD9894ebC0AAc1196c340d8a41A7aB14A7Ae",
  "0x86e5307de5A38738789F1427EE5A0ecc6c500a2D",
  "0x6D4Ae5F7F6b97E4EC39208002d7d4EEd750D192d",
  "0x7E2b02336F2AEBF12114b9B3634f381af1DfeF33",
  "0x54c8f64E743D00D159388197B956B787836F1Fa3",
  "0x1fFE9c766D1b7cD775391b8b2C46e51aA6f0A038",
  "0x36BD6221c2016371C3aF00071726578b91AcB007",
  "0xc919F50Fd75255a42CEDF9c012238BFA0d70E083",
  "0xEf682B1D0835Df1F971d5D1F4565E9f6c121f706",
  "0x0d2BADD61Cd86E0a0Bb80744B6181848ff44b7F8",
  "0x8910fF16C56415a408737812445aAAa78cef1c6B",
  "0xE6a1da162fa649480839Ee96352908fCC3d6e9B7",
  "0xd63F0356e69757b0C3753C25dEA40D31A0bE9006",
  "0xBC9d925270b97Ce0a46CE306aeEF3AA4Ce116844",
  "0xf69CD5Ae4690D736e77FaF93209b96AE358e54ea",
  "0xFB9A1F0808346bb325622950c16A136d0AbCB2Cf",
  "0x832Cf0Afcb378336c8AF9D9904B28C9D65857e38",
  "0xCCb21DAeDA83F0Ff59acE970CbC990BC29Fe6dc7",
  "0xEfB9D0f98BC9a909873158C0f832fA5A60FcF0eb",
  "0x75a869521ddFA498cCd1A93945830bbf49823042",
  "0x4163b00Bc2ba78f2eE5b17F858C32838beb0e29a",
  "0xbdc7b9Cb5FE350fb55a3027D23F9338161F3FCA8",
  "0x935C8E162419b7d71703d0a0278e4529b914C0e9",
  "0x5d48F1A06df6d53E7b66dddCcA48dff829341e39",
  "0xd062Dce9602476e2C2a7699be31833578825BC91",
  "0xfc92B5FAA50350d8dD1AeF8573c50fAa51eb9260",
  "0x5eC1431313e0e9744Ab96d33BcD1F0905E355D91",
  "0x32aAEEE01A1bD496C57FC448947Ad8136C6F4565",
  "0x68eb3A7d6Bb0Abd5C35D3944B4E8beE3489AF8E6",
  "0x5Fd108d58Be6B175F0cad3ef561482EddD9598cB",
  "0xa85E2099AcFe814ebf0df040182b3f14A3B1E34A",
  "0x399D887CEd702fa13bd5002f77E34d08D803E6c1",
  "0x8d5ca1A22202035EE0337eD57ffa8A8832655AA6",
  "0x8E838Ae5e4528BdB1e6A6a102AB3F31dEF399C82",
  "0x5793690F8289423789ACa90449374a1C4b8300b1",
  "0xBb3444a06E9928dDA9a739CdAb3E0c5cf6890099",
  "0x539570468e6319abEcC58f55f02F30CC483A20Bc",
  "0xd9905C40caA9C93867615a571Eef1E3dF9a4045A",
  "0xC46FB314bAb8e2bceEFE79FdA718843a223aBbc6",
  "0x7BF4DbeFBe8023f2EB4A23B872eC735a0B1a30cD",
  "0x48C323447BaAF17E8b36fccee9285129743B6D58",
  "0x1cfcc1476D6a0fE3459867d715c3cE85Eb32D412",
  "0xc79556A222367ECb3c2291d5C6d2B3e58E25FBf1",
  "0x1D432F64767E40a9722EA670353181660183862E",
  "0x8eB178F67Cc4d6eF2f724D6831eD648fab63b8bd",
  "0x1ef3e26351364c64CAbb150187261C843973d6Dc",
  "0x3e78Bf147C94B0bf94b4AB8B1D459c5852b87fa9",
  "0xFC068aCE163036f8Ab3162065af025FC356D9a51",
  "0xFBda58BCAafE5242f3f4aeDDe42dC2966D4d5B48",
  "0x8c5134F94350B26Db435E3048fE1AE822dcA6844",
  "0xa849a5A345a1330D0Ed942113D3596c6339D34D9",
  "0x8F7141213ADC26A54766ba783Cc8f9E98c603723",
  "0x35B7639A8D86950dD641AaE3a33906d18c4f6433",
  "0xF48FF15680f069E0848Ec4a2082a943d0791ee37",
  "0x6B4D3BaE0EBDE422085C010EbCEB6425Fc238978",
  "0x5c4951261B1929eDc82D7fFabA72E9164cf0b8eF",
  "0x7E6703c029AE899e6eBf722Db9c3832f1E8F6fcf",
  "0xb6d1E50dF8A8CF2f966aB982706E6712D39eFA97",
  "0x9Dbef4969a9B01994A05867b2c25c169C60c7048",
  "0xA2FB1ba03507185907906c1E0fdf18b3D8765360",
  "0xa51fC978850C373dd965ab7D1eDF24692d0D0d61",
  "0x0E7a408a775AF29310970dE51B59501e21eEE87A",
  "0xa992AFC09846A822c749d775735e7abE9383801a",
  "0x357038e7Af24eB56B36FDc67397f42ad0599854D",
  "0xc53774F23857661D3e05e4Fa50bF52f37a017e67",
  "0x9CaD606Ef8153B3b06d58A6ecf9E20050c92D8A1",
  "0xa176779B1E0F0e179fB013f137A8C59a062E9c52",
  "0xC9A420E7586D5efEdAa1289f31635aA7DE3FE910",
  "0x99ED97dDe490b7ce8271DA01Db7259c27cEbC031",
  "0x91F1Dde3C6baE9882a25F672d6fB6e6ed333a217",
  "0x6652D68D9ABD9a7c1BA371e6F1803717F4FdbE9e",
  "0xA3123215e33300cFc7228E20eD4a7C437C833A39",
  "0x261b89E16244Fa9a1288E75d06544992a7D63768",
  "0x3963559b05CB75b283Be245aAf1A39E905cC6151",
  "0x281B5DCe9cC3a3eFaB49b7e867Beb76BEcbbf635",
  "0xcDD6d919a40e60962ca3C96445AAC9E078da2ebB",
  "0x8b66b420c4f3137B2Bb4aa6A291bD81790bfecE9",
  "0x4F68C715035649694b677D8fF587dFd2A7f2941c",
  "0xa32dAc7aaFa867Bf2fB8A9d450B5815828BBBE1a",
  "0x6DFBb07cEf6f520E48c37e174bA3374D0A2E8485",
  "0xBFb9915884de2ec1f96D999EC81C29e129035eDC",
  "0xBea2773Ce1Ee42DBD6677B3c246b2A40676474cF",
  "0xFe0dAd687399F50ae473d8028576E1451A53B518",
  "0xCc46f3C48c1F66F6b2D0710385618da93e4CC6c7",
  "0xC0b4FC09b224Ab456D49A2891647cD9376edB7Cf",
  "0xaDF270eE7c2718Ee7E160Ed57B4E1d3f57864Ee2",
  "0xa7db2cb984dB795AE132a14523d9928472360228",
  "0x35dFa1Dbb4b8e82E8a924C53E3649112E45a05F4",
  "0x46A640CA7c78fB5D5f6C7BC25adD61009076995F",
  "0x51F42eA1fa4F8DF653a499AD5EC163326dB21814",
  "0xD11Fc0cc597Ae293755Aac30624555a325B83cb6",
  "0xAabd8BB4881Dc89A790A214024aBF9A11c06E1B4",
  "0x00a58651879Fd753Ab58d639b633368859cDa6e1",
  "0x84F181dc3aD7964a1B2E7CB04336026A5B4ee103",
  "0x6e53002EEa4FB6D9B3aE1c3e30a5B6f4a487Ab2a",
  "0x46BAb2AaE1fa10A6F19Aa522558E03080311E01c",
  "0x71851b9B688824d868B246A901855DA02A47aeD1",
  "0x7B0fAbE6594c973662Bd7d36F19f69FD2Bd25b3e",
  "0x5e5f9994B3A5D5aED15a56168404ddC204845027",
  "0x835F1d5910F6C6523E80E9B7e8E5f31f1a8EE6B0",
  "0xb11Aa600FE3a25085b4EAfD53063074462eD3a3F",
  "0x68134CB81D055A0451Fac78859B3496FAc8DF6bD",
  "0x66EC5ebf30c230976CfdF808a17FbD0ADd302537",
  "0x904A819E486d4af2653Eec8E424599335E2f190E",
  "0xd8dfe217Ebfe59fbFAf4C57bDa54F1476eff5FFE",
  "0xAE19c08Aff950FB252D8F6bcadD3375e2cc4A94b",
  "0x8318B9c0E640114F7eD71De8B65142654573A152",
  "0xaf8c481eB42dEfb31aE67319382624a68e28f5E8",
  "0x780701edA98d4437967881553eFe18dA3bE04249",
  "0x73DEB2Cf82f241922294e50944D2D3830c5cA650",
  "0xE1E57c6d779ADEA0F34CD3B84FB6Bae3a54d1cfd",
  "0xeB915EC165D566ab503F95dF9ace913F2cb45384",
  "0xE89e3A3Ab1AEc780eB5019e1EdC54CB4c7642C20",
  "0xf2cea9C6492348C19Faa54a85fD77965d47fC0ff",
  "0x641e29991b4991a096A40AC1902f52B0393b4669",
  "0x3dDc49998c0b143272c80F3D1CA63b096a093bFC",
  "0x691cE4D3A5F251a750F4012C83A67A9b0B1D867C",
  "0x875A98aE4DfAC08b87810F6725ae2289079684FA",
  "0xE3Bfa1f94dd357316247E6A1d9F6b0C1d78c7621",
  "0xbFCD1a461eED3649D9d92e77d7d848b2c577F7E5",
  "0x5Be3CEF68D71FE556EF6cAA749148fb442b1e6a5",
  "0x5e3c1c9694346a27a7A1E33B904798D5393FAD82",
  "0x2954Be3590C1A91DF845a6c59daC5eec80528B77",
  "0x586e861eC1e3a7DD29Dc6dd7ac00B342aEAAfAEa",
  "0xEBfe1ab93D1122E065adCaFD8C3174261e8E726F",
  "0x1B19bC16Fba358B0F9A7073a8794680401e70657",
  "0x47DD3419FFDD3D227cFaE583eF3B289AEF4C246b",
  "0x340D685a84991668fEdd4e6A2910E9AfBd455148",
  "0x45Ed2481206d9C5404E9E112E00Df491Af897329",
  "0xCA0a10b35b757b35e09452A2BAB3E457e4b9d628",
  "0xCc2E1a8F4FfE542d6658D5a5EF8f5653cEca3b7E",
  "0xA1e9b455A8318efEBEC523b615Eb93854b2dC2D0",
  "0xdFa07eE48f8A36df3068e3d7a0E715fB00b9FD11",
  "0xDd0b532bAEdacbB361C949d295B211F42022Fc79",
  "0x22cb2223e4a4Adf2C5dDD39784da46D7FeCcF735",
  "0xa78Ff646d88f64f8b429B66f3c4211Be65Dff3Cd",
  "0xF175889Cb1aa97D32C262117A3aF1Af04713D97c",
  "0xC567DDdf9fe14C8E4c771F06E949425273078492",
  "0x282ae4f91cE71d44651C0F0632E59414f012aCb7",
  "0x0282ff19D4f3958dd755b30466B83b81a6784dE9",
  "0x2710dEA7181445B0982b0A51366c3071f07d962f",
  "0x6c2A1f27514ecd69123d41B55b06cd3885FFa51d",
  "0x1816CB6729F376A0EA09dff21c86a74DC704853b",
  "0x759d705b2Cf2CD23ff0A059639635D9E48782F71",
  "0x7bEED30332656CA4220CB2ce8E4508fb18013E8d",
  "0x7ab125904AF75823858BdC7259A08B581999C0AE",
  "0x624f3052d4EA03823C0D194f92AEe4C7D310Fe24",
  "0xc445c9dF31994794b4eF06a2D6d58a6CDA56F6e3",
  "0x479b537C591877B7b6F7a084E9B07D90C4bFf688",
  "0x21c61c10Ed3C3C0615aA60CeE4250Bef70c7a624",
  "0xA33EE8dA1F8E8AF1F189A252364bb11dF5B13Ac6",
  "0xdf6160fE3E5A7D6df67c2BB3D7E100B47eFA243C",
  "0xDAff7CCb1B7B9d008b977be2611a1149c797f754",
  "0x4F21aAf597EeC866396eDA33b3CBF0C9b72FB3aD",
  "0x0C481Cd7D09Bdd222351d52622D272367CB3D159",
  "0xDF067d1c62B6a94e45ceD3f01EbC94AE304Cb045",
  "0x3D521F1F6Ee48604918210F31502A12fd03ae273",
  "0x046CD19A339761C8f62FFA5C42023bC5E07e7c5C",
  "0x5124B17B55f4c8f4395a06A461c5109cF3d5f7eB",
  "0xB802162900a4e2d1b2472B14895D56A73aE647E8",
  "0xd86057c793bDFb77BB11dCAbfe054eE4fd86EbdD",
  "0x46ff0E2D5C5425b7fd8E6eF7108b94956d90F8F8",
  "0xc72f40a397453051349F73cf2E2a04Fac06E37a3",
  "0x6f7a40FFC9302Cec10b7F9B1E919B6696e36b9FC",
  "0x576a655161B5502dCf40602BE1f3519A89b71658",
  "0xDcaE87821FA6CAEA05dBc2811126f4bc7fF73bd1",
  "0xAb90dDDE2cf6a4753106a058ACb4Cc7412A58AE7",
  "0x8688F08eB5811a17683850ee5f63C5812604e4d6",
  "0x6527939C107C507e22Cec1F6740719a26A642Ec1",
  "0x20e7D25C324E143FF897e75d4d68cB28A1F6D9A5",
  "0x4cCc0AA065A37A3589e2Db6E165d2f8F522E9FA2",
  "0x47527eA6eBed6D5769609dD187054B7D763131C9",
  "0x734Ed31ad4853ECb36E8554Ec35408652642F3a0",
  "0x31930dC73753dF11424a1BA49B4d836C14bDA4d0",
  "0x1a605ce6FDa8659f0DA60149923e4431D5802063",
  "0x8469B7b08D30C63fEA3a248a198dE9D634B63d70",
  "0xcac1539B0888AC6B43B594DC39d2C6b05e9C422F",
  "0xFCc2B4Fb323d83570AF61C9448280cb2e0a02979",
  "0x1351d2cf237DC34EeEc11f0E3740f0245F61A54e",
  "0x4c295778C740034BF4B98487Af2B1E0d60316995",
  "0x2B76733dE3be8BaD89156ecD50170B9F2A6Fa86A",
  "0x9DDaE9B61B790E16d4Fe92A6eDE1339dA0F9e7BD",
  "0xD7D4F0D1D46443FA79B81B3C3e6E578b98BdD0e8",
  "0x4DA45fdd66678Ea768248eF69E7Faf5C897cb70f",
  "0x56DBC92C5863f64EFC5A8B717D3860D835a78617",
  "0xe6e1aF752e9AA417Db5bD027D11B5cCc97bAd9b9",
  "0xA421D48E2F17402C8165fC4C46AC78CADbd858A9",
  "0x504CBE8565ef608f9544E59158D7d5D86eCe18eA",
  "0x6CB0921427bE9D9A68F217475c34F131F5B8b869",
  "0x7cFBFda5525508D462EB8523Dd1f5d4491E048D3",
  "0xD4F802ec4f588952a3A92fce0d5d3629e78d4F57",
  "0xD1B3976CD24333C68dC6746f891FC698dA1c0a4A",
  "0x8ce9F7B042FCe1971B73240d16849A684A2657d5",
  "0x3090fB16a6fcf9f85A8d55B710958227154f5083",
  "0xD665afb9A4019a8c482352aaa862567257Ed62CF",
  "0xedE681BC254207fa86077Cb740DC667978e8B6f2",
  "0xCd7e89F8ADc80AaAda5899312A1132209650eD0E",
  "0xB0D740D76340Ef6c4515f30e9ff61C779cD1f86b",
  "0x44d666FF4866a9E40de0A634c1e3685e7c790F33",
  "0x042BA4417678DA52b73D59cCF65fB08e47FdbB60",
  "0x8f489A0F2bfcea788163c7032388717306aF387B",
  "0x2054804fb9Fb267939011159E1D367E3c85845ba",
  "0xacfBc87D4ff16bc6d2dF6D383c25ffe7EFa56DA8",
  "0xCDD99ee657A33b1DA6802F4117D7e5cB2FFA5d79",
  "0x94C2a349A528180281E0c6F4fbAcB544cA72bEB3",
  "0x25397D8C3FCf06DeD9902249560d5D6B7Cd5DBE0",
  "0x18aB104b9B143aaff5111234Ebda33d0DC4E401F",
  "0x9997205Cd290fb2D7bf537C919a572D16E5020a1",
  "0xe036a80f628e531982189fb6C4F277DEca521E36",
  "0xC839de0fEd241607d50aa4107aE582443B906e4c",
  "0x0a834BBC8D2273C2416ef1cD7A4DF735f60CD751",
  "0xE588A2b38F24C72e30535885d645CEbA44480D1b",
  "0xc1FdBb61Ea97961799EC42818cF0DF9D6Be7aEC4",
  "0x2D3e2776404CEe093599D429257B6B42b14912d6",
  "0xCeAe52C73db66e280F8D5bE3Db89DC69463c7d72",
  "0xc01c99b2543e28EFf240e90384A1fd757a484927",
  "0x888515858aEF60A684749Ca156Ce0B0725ce6b5C",
  "0x0CE6451bCDcbe5C15179c566547F41cA62904f36",
  "0xa8C10eC49dF815e73A881ABbE0Aa7b210f39E2Df",
  "0xf9f7aE25e92E3573C5C2E3C97D2658BD2c06dDf6",
  "0x4DE31FfA366271f90a6DA19130d7aE8bA2541437",
  "0xe37567Fe840baae0c7beA95b7d6512d3A7289fcE",
  "0x78561aC59aa83D35f02c2983696291BD1Ab55B64",
  "0x69ECA407a030554444b7328eDEea31ad0e37240a",
  "0x8F9672ADC55F8f5f840E2aeD9338b8E8c11188a9",
  "0x4683aeF58084FC762ea37fA51323898130178247",
  "0xC020388cEB7f8d10dF56be8ddbcf7ab6615838FF",
  "0xB2f8E4163142c03505B17acE865FAd5208D6d6a4",
  "0x9aF7Edc48Bc8Db5E4AFD3aF95DF143ea29E2E2D6",
  "0x1Ae50668Be1f32179bcE00eb203121F00907D808",
  "0x49a930fdDb8c10C02D4ABe53156e33C61bD31eAB",
  "0xE37C3Eb4F67d318503FAa5F84e20C49CE59b9850",
  "0x2C4Cf54dB89F84221692bE6c74Cc6b7f72534836",
  "0xCD3811f1900A0ff9c0c1DBC846Ef65e9861B260d",
  "0xdCE6F029213475B1C2d2f7c750617e470D0dd65A",
  "0x6372d3C71AA13Be4942b1c854b01608F97572e3A",
  "0xca4cd1152aabCb80859ADD1406Ab7Ec55E963e28",
  "0xbA2B0fe6f96d70A29aB2DA1AfEaEcDc2208135c8",
  "0xca1F85Dcf517E28497291CCD00990c390fA6b9f7",
  "0x3480d164cebA4Eb8eA17e7F64810707a55E1F76B",
  "0xc0dD7113f98cEb571B74f9473f138D83E8Dd43Ba",
  "0x5dE0B90938EA8964D52863dD22EE9Ae55f64c9e7",
  "0x1F357F2C89D5572a0721e0C11CE1cB1cEB9CAfE4",
  "0x3d4568c59ADFe6a045ac68f00dF27e19a61C2a5F",
  "0x9993Da67f310669D33aB5fE159792582c3BDBF36",
  "0xB89d68928eCAE680c0A5B0444e361A5B62F69554",
  "0xa5FF0d30460e7790CC4C7E37AAd1CE86EFc50E2B",
  "0xDF64181F12aC190BeE4E1B8406C0bdeE5498e367",
  "0x5dFa5f04F86406544fd5135d65d745069427525f",
  "0x08d8604a1C24fd74b85366aeafB01186D49708AF",
  "0xBf46BfFa4d12D2dD9a998a9b8D0d1f6647720d84",
  "0x91737796B4085f183C6d67C5FE631BFE2a1706f3",
  "0x15fD7178cabEd50e9008cB42b474621e9b07CD5f",
  "0x75e5052d53dfef2Dd831814B8Da723900FA1e337",
  "0x73C503796cfD6E248135E8B106913C9BE2a57aa6",
  "0x73aA12b47Be2E0c0B116435bA970DA8971936471",
  "0x458F31e446c5AC173833030474a1F81cc43B654b",
  "0x0d13c6d1dBe2B7313f288180A3e3CA56B463D0AA",
  "0xe75eDD240A14D0DCBD5C0ec1aBe062A2222993A8",
  "0x0c2269109573faeE2f1F0bAd05aAE00d027D292F",
  "0x1fcdC0C3E42C8787F92e197D6404A8AD88DF66Da",
  "0xF9D6fcE6Cce28c0Cd62c5CAf045f4F6233888989",
  "0xA3B1F6621199e5D5E10FFa3AB1fdf57869b331cC",
  "0x9a9559a0aA615fEB70FD78057089dC4860b433C8",
  "0x3327F53140683d19E2BaAEB65054133B9B33c53D",
  "0xa42E1dEBEbA42786a4d2b8226927291462271e96",
  "0x0336C8E8eE1347A31F1a61Dc6087331d6Cb3B001",
  "0xDA21f63Dcbb82c760E5426c630D35489c7d0560b",
  "0x890166F076C2fd60b5814fa33900A26c6B8A6345",
  "0xbd890A8Dd9d7580Ac97444AD267ed1e13D48C1cb",
  "0xd7d126110624A753cD36A5Bbcc0345825EE428Ec",
  "0x3A8B9ee90835B5955e0847E94EAC7Bdc76b17D60",
  "0x0EF7b86A835c3b84D2A9FD2dC6351AD2E0e2A9F1",
  "0x2B4E8BFe0C845a181c6C0e73728a9cEB917A985c",
  "0x2c3b3FEa418Bf2d82ab2036ee3a35cD9648Fab7a",
  "0xf49f8FC4B103CCBD88bbdE745e2F7FBEfC67456e",
  "0x99Ff73FAdC94cfe4c83701939EE6E94A66822117",
  "0x474B6c2366128eaCAeA1b6e411171c06B49BDC97",
  "0xC8452dF230c844F73D714b64d2FC6F4cDB97371B",
  "0x10391D6FAd544d134a860b7eEd6E9f9d84CB9b81",
  "0xAbb473BCC5f6CDB27208371E153e4288b4D3DBa1",
  "0x509A6aaB94FdecEB5231F255eAfd9B9266AE9b49",
  "0xF248a71Dc0b1bBD4ffB6093207C78A9eeCD1c2ca",
  "0x7b50b1f2634Fa38d078eb067A4f8f22a666B49a2",
  "0x48A50108e881755a70EB6d6660748fC7A6feA14D",
  "0x1B606F81abC2F6445b8b52158C6682695FD30F50",
  "0x447E55CF025F187Bc09a9d0f237F7B2203541B66",
  "0x4276d883b78d0914C8006aA83eAab56e9F0204eE",
  "0x596DC4Bb87c9D7dc4e227906b2fd6651AE2E6FD9",
  "0x10e965238eE608d4D45d56E6e9CaA9D96ea30455",
  "0xb84BE5aF8dC1B039531214c09170f650728B1ce9",
  "0x5fE836B10d823836787eaEff1e6ac722e1D38971",
  "0x1517FCdb51Ba20f189D7aa0dC6dE73A5519eCAb3",
  "0x0298b637B80b269fef73F13d9F8101116F16920e",
  "0xCbaCF34d901375F28CdA702734C02044E933ECB5",
  "0xa184C07201b6E3c18013fA6b3F9C76a7A61C4Fc7",
  "0x510016342b92cD5f49d129013900f752A3171682",
  "0x9BFa80eeF28a59b86e8981195883CB5dB28fCc1E",
  "0xd7aFbcB452066763184483B5227f8aC9A61203d3",
  "0xc5E08104c19DAfd00Fe40737490Da9552Db5bfE5",
  "0xad01AB117a39bd02A4caeCdA8d449070B7149B06",
  "0xe5451A8651fF622fb3A111633E0c709379AAf4a1",
  "0xF7C4574FBEf01B5281Fd6a705e3E59278CA390c5",
  "0x6e977c8155D914E7Aa99331169939978A4d1B102",
  "0xf1d83de0eAd95747f4AC184b36659c0D538309C8",
  "0xFAc0D96c6C94C269F27f508dE8A44455e89CC92E",
  "0x57AA36d30d315791A8222D5fBFD76c1608E961cC",
  "0x4c2F526CDAAEeA289FB02fce54aC5CF9ffB5229b",
  "0x5f87C9E766A6593c83f78E3E55e92e2524899BD8",
  "0x9aa79E8afa349DEC0b973203232089A5855bEdba",
  "0x3E0C3b1Aa714c008255fa577cc3D4efa658Aa655",
  "0x47C553E889f7BFA75Cf3775473180A04E9eFfCfA",
  "0xe8bee2F6BF252Ed9eFBbd483cA4093aCe7350c92",
  "0xe6Ba7CE1bBd7b811d891C2D4C09D7295bb96752c",
  "0x026e3A7CD4ECF91A8aD116A6124880D876AF3c99",
  "0xAB81BE93F28664DaF0c1638b483e4f9D2539446e",
  "0x1C5762F9899B46c19d4C9B8FBDD38D35856227f3",
  "0xB580c6aEBF53dbFaE34830cb0AE134Cf60aC732c",
  "0x82F23De5A474fC904041c357576aFE53c30dD250",
  "0x19e7A132bD4B42F580f546197f42e19c42Cdfe6c",
  "0x811E19f84C66A9E74830A48cF04aA3AaA71aCd1B",
  "0xa6e8E4Bf375BF380688D375885836DB6222763f6",
  "0x208833804D09Cf965023f2Bdb03d78E3056b4767",
  "0x391A3699f430A4563097aEC735b3C242108350Ca",
  "0xFa002B52433a68EEd6ED254569110A294B5EFb74",
  "0x3F1e2953ec44A6D73ab54E5C6a07B099E7DAC7d2",
  "0xE066C7B1fEeE619F88D2fdfc7EAb3fc7B75CE6bb",
  "0x2531896dFb32418BAbF6c57edD40f8EE53C0f236",
  "0xF1e9259dB3999a1b28fCA4c81F2B1caC5E5e3836",
  "0x95ebFd2eF843E8B920840BD8101A12EaAeae156D",
  "0x014361A9a9c55c6442281eF6534e731442ed06c0",
  "0x4906E4f95ad546cE865916F65C825e00630bFfa8",
  "0x0aAA92F5ACa880179e6C53F13c95d42537b2a1b6",
  "0x056Af233c0a208d9882DA504ECF1A59634a68408",
  "0x0F92D39489433041ceF0D7d4fD322434A941b068",
  "0xF1D8F13Fd137669ac768EDf467f358200FafbA72",
  "0x6c270A8E67a42a12680CA4b17262353189E103B4",
  "0x27b455C481D62f91a7B55d09Cf2E3cd70858EF85",
  "0xFc2d838543FC4389791C1F083256407110Ae0294",
  "0x4a22910F4db3E7BF000EaCfa06126D5Db7f0EfD5",
  "0xD809a687e957761872B440909348FA6547CafaBf",
  "0x830540B0750350b0e17c377c1Cc56b19b7AF4885",
  "0xb154b590D9FBB17c7B6166F16f389F0121186d3A",
  "0x3d46548504f52B7B3B64882674eB80eCCa98263f",
  "0x71a548C09c6d09DF38811c6dF025d281e878258b",
  "0x6B6Cf175b3dc7A732A1CEA4A3EECCAFEE8D2dc85",
  "0x8Fc54E3d572012099F3f48369657d58F653E92e4",
  "0x11B6691b22e4Ae516BCf4482579CC206a0A9DF62",
  "0xcb1803FF8BEc838BC7331031c7918bf21759395b",
  "0x985Ac83806bfCa7Bf4C968dF2032CB0827C4D457",
  "0xe874BA46982D7cBaB5931a50c2DC81A7aEb80344",
  "0xDE93D122b3ae8DCdb49ac4D77C3CaB960955b1f0",
  "0x6191C173e855202083455859E670Ac16a669788d",
  "0x622bdbf43d9d47A5320c7fD907123F520cdb7063",
  "0xBa12cbeF3c43C0c0719f22bf781Cd2714dB093d3",
  "0x10a68f29B5f9a1d94AF3242a6616a834EEBDA495",
  "0xa702FF4A128e03ae776613A1758fFAa34069C53c",
];

export const etherscanUrl = "https://etherscan.io";

export const allowListPrice = 0.01;
export const publicSalePrice = 0.02;

export const allowListWindow = 1800000;
export const publicSaleWindow = 1800000;

export const startingDate = 1701969360000; //1701966720000;

export const artistName = "Charlesai";
export const bio =
  "I'm Charlesai, a French artist and doctor. I'm most known for being early in creating ai art and minting it as NFTS back in 2020. Since then, my art has entered the collection of some of the most notable patrons of the arts and has been since exhibited worldwide (Singapour, Paris, New York, California, Milan, Rome, Nice, Switzerland…). Through my works I focused on expressing my views on our contemporary world, exploring the themes of climate change, crypto markets, mental health…";

export const timeUnits = ["minutes", "hours", "days", "weeks"] as const;

// change this in env variable for production
// export const authKey = CryptoJS.enc.Hex.parse(
//   "31318ea9e41e20134fa028a88316619f6e879790bcf8dbdea9d50d9633bcc96b"
// );
export const authKey = CryptoJS.enc.Hex.parse(
  process.env.REACT_APP_AUTH_KEY as string
);

// change this in env variable for production
// export const iv = CryptoJS.enc.Hex.parse(
//   "ea3ef26ba9c0d4ff047e929be3179f62875925eb11bba069e4d750f4c099414a"
// );
export const iv = CryptoJS.enc.Hex.parse(
  process.env.REACT_APP_AUTH_IV as string
);
export const ninfaUrl = "https://ninfa.io";

export const artistUrl = "https://ninfa.io/@charlesai";
export const artistTwitter = "https://twitter.com/HODLFrance";
export const artistInsta = "https://www.instagram.com/charlesai.eth/";

export const validChainId = 1;
