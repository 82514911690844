import React, { Suspense } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

type Props = {
  children: React.ReactNode;
};

const Layout = (props: Props) => {
  const { children } = props;

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          maxWidth: "80%",
        }}
      >
        <Navbar />
        <Suspense fallback={<div />}>{children}</Suspense>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
